import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private snackbar: MatSnackBar) {}

  public showSnackbar(message: string, action?: string, duration?: number) {
    this.snackbar.open(message, action, {
      duration: duration ? duration : 10000,
    });
  }
}
