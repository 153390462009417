<!-- class mat-checkbox-checked probably shouldnt always be on (white background) -->
<div #checkbox class="mat-checkbox mat-checkbox-checked">
  <label [attr.for]="inputId" class="mat-checkbox-layout" #label>
    <span
      class="mat-checkbox-inner-container"
      [class.mat-checkbox-inner-container-no-side-margin]="
        !checkboxLabel.textContent || !checkboxLabel.textContent.trim()
      "
    >
      <!-- [attr.aria-checked]="_getAriaChecked()" -->
      <input
        #input
        class="mat-checkbox-input cdk-visually-hidden"
        type="checkbox"
        [id]="inputId"
        [required]="required"
        [checked]="checked"
        [attr.value]="value"
        [disabled]="disabled"
        [attr.name]="name"
        [tabIndex]="tabIndex"
        [attr.aria-label]="ariaLabel || null"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-describedby]="ariaDescribedby"
        (change)="_onInteractionEvent($event)"
        (click)="_onInputClick($event)"
      />
      <!--       [matRippleDisabled]="_isRippleDisabled()" -->
      <span
        matRipple
        class="mat-checkbox-ripple mat-focus-indicator"
        [matRippleColor]="rippleColor"
        [matRippleTrigger]="label"
        [matRippleRadius]="20"
        [matRippleCentered]="true"
        [matRippleAnimation]="{ enterDuration: 150 }"
      >
        <span class="mat-ripple-element mat-checkbox-persistent-ripple"></span>
      </span>
      <span class="mat-checkbox-frame"> </span>
      <span
        class="mat-checkbox-background"
        [style.backgroundColor]="checked ? color : 'unset'"
      >
        <svg
          version="1.1"
          focusable="false"
          class="mat-checkbox-checkmark"
          viewBox="0 0 24 24"
          xml:space="preserve"
        >
          <path
            class="mat-checkbox-checkmark-path"
            fill="none"
            stroke="white"
            d="M4.1,12.7 9,17.6 20.3,6.3"
          />
        </svg>
        <!-- Element for rendering the indeterminate state checkbox. -->
        <span class="mat-checkbox-mixedmark"></span>
      </span>
    </span>
    <!--     (cdkObserveContent)="_onLabelTextChange()" -->
    <span class="mat-checkbox-label" #checkboxLabel>
      <!-- Add an invisible span so JAWS can read the label -->
      <span style="display: none">&nbsp;</span>
      <ng-content></ng-content>
    </span>
  </label>
</div>
