<button
  (click)="handleButtonClick($event)"
  matRipple
  [matRippleColor]="outline ? null : '#ffffff33'"
  [matRippleDisabled]="disabled"
  [disabled]="disabled"
  class="asuvi-icon-btn-lrg"
  [ngClass]="{
    'asuvi-icon-btn-lrg-outline': outline === true,
    'asuvi-icon-btn-lrg-disabled': disabled === true
  }"
>
  <img *ngIf="imgSrc" [src]="imgSrc" />
  <ng-content></ng-content>
</button>
