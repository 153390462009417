import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(public Dialog: MatDialog) {}

  ngOnInit(): void {}

  showDialog() {
    this.Dialog.open(DialogBoxComponent, { height: '531px', width: '674px' });
  }
}
