import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { setContext } from '@apollo/client/link/context';
import { take } from 'rxjs/operators';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { environment } from 'src/environments/environment';

const authContext = (auth: AngularFireAuth) =>
  setContext(async () => {
    const token = await auth.idToken.pipe(take(1)).toPromise();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

export const createApollo = (
  httpLink: HttpLink,
  auth: AngularFireAuth
): ApolloClientOptions<any> => {
  return {
    link: ApolloLink.from([
      authContext(auth),
      httpLink.create({ uri: environment.apiURL }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  };
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AngularFireAuth],
    },
  ],
})
export class GraphQLModule {}
