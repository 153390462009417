import { Injectable } from '@angular/core';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { map, take } from 'rxjs/operators';
import {
  CalendarInsertInput,
  CalendarProvidersEnum,
  EventsUpdateColumn,
  MySyncedCalendarsGQL,
  UpsertCalendarsGQL,
  UpsertEventsGQL,
  DeleteEventGQL,
  DeleteRecurringEventsGQL,
  MyCalendarsGQL,
  SyncExternalCalendarGQL,
  Provider,
} from 'src/generated/graphql';
import { AuthProviders } from '../models/auth';
import { ICalSync } from '../models/calendar';
import { AlertsService } from './alerts.service';
import { AuthService } from './auth.service';
import { GoogleCalendarService } from './calendar-providers/google-calendar.service';
import { MicrosoftCalendarService } from './calendar-providers/microsoft-calendar.service';
import {
  GCalAccessRoles,
  IGetGCalEventsResponse,
} from './calendar-providers/models/google-calendar';
import {
  IGetOCalEventsResponse,
  IOutlookEventResource,
} from './calendar-providers/models/outlook-calendar';
import { formatGEvents, formatOEvents } from './utils/format-calendars';

@Injectable({
  providedIn: 'root',
})
export class CalendarSyncService {
  constructor(
    private auth: AuthService,
    private alerts: AlertsService,
    private gCal: GoogleCalendarService,
    private outlook: MicrosoftCalendarService,
    private upsertCals: UpsertCalendarsGQL,
    private mySyncedCals: MySyncedCalendarsGQL,
    private upsertEvents: UpsertEventsGQL,
    private deleteEvent: DeleteEventGQL,
    private deleteRecurringEvent: DeleteRecurringEventsGQL,
    private myCalendars: MyCalendarsGQL,
    private syncExtCalendar: SyncExternalCalendarGQL
  ) {}

  /**
   * Sync user calendars from thier providers
   */
  public async syncCalendars() {
    if (await this.auth.isLinkedWithProvider(AuthProviders.GOOGLE)) {
      await this.syncGoogleCalendars();
    }
    if (await this.auth.isLinkedWithProvider(AuthProviders.MICROSOFT)) {
      await this.syncOutlookCalendars();
    }
  }

  /**
   * Fetch calendars that were synced to the DB
   */
  public async fetchCalendars() {
    return this.myCalendars
      .fetch()
      .pipe(map((res) => res.data.Calendar))
      .toPromise();
  }

  public async syncExternalCalendar(
    calId: string,
    provider: Provider,
    sync: boolean
  ) {
    const res = await this.syncExtCalendar
      .mutate({ calId, provider, sync })
      .pipe(map((res) => res.data.syncExternalCalendar))
      .toPromise();
    if (res.error) {
      this.alerts.showSnackbar(res.error);
    } else if (res.sync) {
      // calendar is now synced, fetch and store events into db
      await this.syncCalendarEvents([
        { id: calId, provider: (provider as any) as CalendarProvidersEnum },
      ]);
    }
    return res;
  }

  /**
   * Syncs the calendars on your Google Account to DB
   */
  private async syncGoogleCalendars() {
    const response = await this.gCal.getCalendars();
    const calendars = response.calendars.map((cal) => {
      const {
        id,
        accessRole,
        backgroundColor,
        primary,
        summary,
        description,
        summaryOverride,
      } = cal;
      const canEdit =
        accessRole === GCalAccessRoles.WRITER ||
        accessRole === GCalAccessRoles.OWNER;
      const title = summaryOverride ? summaryOverride : summary;
      return {
        id,
        provider: CalendarProvidersEnum.Goog,
        canEdit,
        title,
        description,
        primary,
        color: backgroundColor,
      } as CalendarInsertInput;
    });
    return this.upsertCals
      .mutate({ objects: calendars, provider: CalendarProvidersEnum.Goog })
      .toPromise();
  }

  /**
   * Syncs the calendars on your Microsoft Account to DB
   */
  private async syncOutlookCalendars() {
    const response = await this.outlook.getCalendars();
    console.log('This is the response', response);
    const calendars = response.calendars.map((cal) => {
      const { id, canEdit, hexColor, isDefaultCalendar, name } = cal;
      return {
        id,
        provider: CalendarProvidersEnum.Msft,
        canEdit,
        title: name,
        description: null,
        primary: isDefaultCalendar,
        color: hexColor,
      } as CalendarInsertInput;
    });
    return this.upsertCals
      .mutate({ objects: calendars, provider: CalendarProvidersEnum.Msft })
      .toPromise();
  }

  /**
   * Get the list of calendars that we can sync
   */
  public async syncAllEvents() {
    const res = await this.mySyncedCals.fetch().toPromise();
    return this.syncCalendarEvents(res.data.Calendar);
  }

  // TODO: Error catching
  /**
   * Sync one or more calendars from database
   * Logic/Implementation idenitical for cloud sync
   * @param cals Calendars to sync
   */
  public async syncCalendarEvents(cals: ICalSync[]) {
    for (const cal of cals) {
      const { id, provider, lastSynced, syncToken } = cal;
      // if no provider or calId
      if (!provider || !id) {
        throw new Error('Missing calendar provider and/or id');
      }

      // If calendar was last synced last month or no sync token
      console.log('this is the cal', cal);
      if (this.checkIfPreviouslySynced(new Date(lastSynced), syncToken)) {
        // Generate new window (4 months)
        if (cal.provider === CalendarProvidersEnum.Goog) {
          await this.syncGoogleEvents(id);
        } else if (cal.provider === CalendarProvidersEnum.Msft) {
          await this.syncMicrosoftEvents(id);
        }
      } else {
        // Use the sync token
        if (cal.provider === CalendarProvidersEnum.Goog) {
          await this.syncGoogleEvents(id, syncToken);
        } else if (cal.provider === CalendarProvidersEnum.Msft) {
          await this.syncMicrosoftEvents(id, syncToken);
        }
      }
    }
  }

  /**
   * Sync Google Calendar Events
   * @param calId Google Calendar ID
   * @param syncToken Previous Sync Token if applicable
   */
  private async syncGoogleEvents(calId: string, syncToken?: string) {
    let res: IGetGCalEventsResponse;
    if (syncToken) {
      res = await this.gCal.getEvents(
        calId,
        syncToken,
        null,
        null,
        null,
        null,
        true,
        true
      );
    } else {
      const { timeMin, timeMax } = this.generateTimeDelta();
      res = await this.gCal.getEvents(
        calId,
        null,
        null,
        null,
        timeMin,
        timeMax,
        true,
        true
      );
    }
    console.log('these is res', res);
    if (res.cleanStore) {
      // Clean the entire events history
      console.log('cleaning store');
      return this.syncGoogleEvents(calId);
    } else if (res.error) {
      throw new Error(`GCal Events Sync ${res.error}`);
    }
    console.log('these are the updated events', res.events);
    const events = formatGEvents(res.events, calId);
    console.log('these are the formated events', events);
    const mutationRes = await this.upsertEvents
      .mutate({
        objects: events,
        calId,
        provider: CalendarProvidersEnum.Goog,
        syncToken: res.nextSyncToken,
        updateColumns: this.updateAllEventColumns(),
      })
      .toPromise();

    console.log(mutationRes);
    return;
  }
  /**
   * Sync Google Events: adding new events, and deleting events (recurring, single-instance)
   * @param calId Microsoft Outlook Calendar Id
   * @param syncToken Previous Sync token if available
   */
  private async syncMicrosoftEvents(calId: string, syncToken?: string) {
    let res: IGetOCalEventsResponse;
    if (syncToken) {
      res = await this.outlook.getCalendarDelta(calId, syncToken);
    } else {
      const { timeMin, timeMax } = this.generateTimeDelta();
      res = await this.outlook.getCalendarDelta(
        calId,
        null,
        null,
        timeMin,
        timeMax
      );
    }
    if (res.error) {
      throw new Error(`OCal Events Sync ${res.error}`);
    }
    console.log('these are the events from outlook', res);
    const seriesMasters = {};
    const events: IOutlookEventResource[] = [];
    const deletedEvents: string[] = [];
    // Determine which events must be added to DB and which to mark for deletion
    res.events.forEach((event) => {
      if (event['@removed']) {
        // See more: https://docs.microsoft.com/en-us/graph/delta-query-overview#resource-representation-in-the-delta-query-response
        deletedEvents.push(event.id);
      } else if (event.type === 'seriesMaster') {
        // Series masters provide a reference but are not real events to track
        seriesMasters[event.id] = event;
      } else if (event.type === 'occurrence' && event.seriesMasterId) {
        // Stitch event from series master
        events.push(
          Object.assign({}, seriesMasters[event.seriesMasterId], event)
        );
      } else {
        events.push(event);
      }
    });

    // Take the events from Graph API --> Convert into schema to insert into DB
    const formattedEvents = formatOEvents(events, calId);

    console.log('recieved this from the endpoint', res.events);
    console.log(
      'these are the events we must sync these changes',
      formattedEvents
    );

    const deletionPromises = [];

    // Delete previous instances of a repeated event
    if (Object.keys(seriesMasters).length > 0) {
      Object.keys(seriesMasters).forEach((key) => {
        deletionPromises.push(
          this.deleteRecurringEvent
            .mutate({
              recurringEventId: key,
              provider: CalendarProvidersEnum.Msft,
            })
            .toPromise()
        );
      });
    }

    // Mark single events for deletion
    if (deletedEvents.length > 0) {
      deletedEvents.forEach((eventId) => {
        deletionPromises.push(
          this.deleteEvent
            .mutate({
              eventId,
              provider: CalendarProvidersEnum.Msft,
              deleted: true,
            })
            .toPromise()
        );
      });
    }

    try {
      const deletionRes = await Promise.all(deletionPromises);
      console.log('these are the deletion promises', deletionRes);
    } catch (err) {
      console.error('Outlook Cal Sync Deletion error', err, calId);
      return;
    }

    const mutationRes = await this.upsertEvents
      .mutate({
        objects: formattedEvents,
        calId,
        provider: CalendarProvidersEnum.Msft,
        syncToken: res.nextSyncToken,
        updateColumns: this.updateAllEventColumns(),
      })
      .toPromise();
    console.log(mutationRes);
    return;
  }

  /** Helpers */

  public generateTimeDelta() {
    const timeMin = startOfMonth(new Date());
    const timeMax = endOfMonth(addMonths(new Date(), 3));
    return { timeMin, timeMax };
  }

  private checkIfPreviouslySynced(lastSynced: Date, syncToken: string) {
    return (
      (lastSynced ? lastSynced : new Date(0)) <= startOfMonth(new Date()) ||
      !syncToken
    );
  }

  private updateAllEventColumns() {
    return [
      EventsUpdateColumn.AllDay,
      EventsUpdateColumn.Attendees,
      EventsUpdateColumn.Creator,
      EventsUpdateColumn.Deleted,
      EventsUpdateColumn.Description,
      EventsUpdateColumn.EndDate,
      EventsUpdateColumn.EndTime,
      EventsUpdateColumn.ICalUid,
      EventsUpdateColumn.IsDetached,
      EventsUpdateColumn.Location,
      EventsUpdateColumn.Notes,
      EventsUpdateColumn.Organizer,
      EventsUpdateColumn.RecurrenceRule,
      EventsUpdateColumn.RecurringEventId,
      EventsUpdateColumn.StartDate,
      EventsUpdateColumn.StartTime,
      EventsUpdateColumn.Status,
      EventsUpdateColumn.Timezone,
      EventsUpdateColumn.Title,
      EventsUpdateColumn.Url,
    ];
  }
}
