export enum AuthProviders {
  GOOGLE = 'google.com',
  MICROSOFT = 'microsoft.com',
}

export enum AuthCookies {
  MSAL = 'MSAL_ACCESS_TOKEN',
}

export enum SignInMethod {
  POPUP = 0,
  REDIRECT = 1,
}
