<div class="box">
  <div class="title">Create New Group Calendar</div>
</div>
<div class="box">
  <div class="words">Give your calendar a name and description.</div>
</div>
<br />
<div class="box">
  <form [formGroup]="NewCalendar" (ngSubmit)="saveButton($event)">
    <div class="fieldTitle">Calendar Room Name *</div>

    <input
      matInput
      type="text"
      formControlName="roomtName"
      Validators.required
    />

    <br />
    <br />
    <div class="fieldTitle">Description (Optional)</div>

    <textarea
      matInput
      formControlName="Description"
      rows="7"
      cols="63"
      Validators.required
    ></textarea>
  </form>
</div>
<div class="buttonbox">
  <div class="buttonPosition">
    <div class="cancel-button-box">
      <button
        class="button"
        mat-raised-button
        (click)="CancelButton($event)"
        color="white"
        mat-dialog-close
      >
        Cancel
      </button>
    </div>
    <button
      class="button"
      mat-raised-button
      (click)="saveButton($event)"
      color="primary"
    >
      Save
    </button>
  </div>
</div>
