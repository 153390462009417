// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  redirectUri: 'http://localhost:4200',
  apiURL: 'https://api.asuvi.dev/v1/graphql',
  requestURL: 'https://asuvi-backend-ebmp35cd6a-uc.a.run.app',
  firebase: {
    apiKey: 'AIzaSyBAuq8YPr-Rf7o4GYPjbgv8utO9iegETPY',
    authDomain: 'asuvi-dev.firebaseapp.com',
    projectId: 'asuvi-dev',
    storageBucket: 'asuvi-dev.appspot.com',
    messagingSenderId: '541245540687',
    appId: '1:541245540687:web:240673a066a5e3134d5d3a',
    measurementId: 'G-YLY8D53P3P',
  },
  googleCal: {
    apiKey: 'AIzaSyCE-gA_E3OzbjZK9xFzKtFUP9cuZu1SsIw',
    clientId:
      '541245540687-0pms4pansnhsogf1jq94b4oe1n5hcffg.apps.googleusercontent.com',
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    ],
    scope: 'https://www.googleapis.com/auth/calendar',
  },
  microsoftGraph: {
    scopes: ['user.read', 'calendars.readwrite'],
  },
  stripe: {
    publicKey:
      'pk_test_51IBTVwJVkPfWrJcaJrBTIUZ1KkjX82D67ROk4H7YIRkbA9jww4A6vQMixgSg22ERDbcwln7MVQMYIfdNPMXUqqha00FyjJlX1W',
    defaultCurrency: 'USD',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
