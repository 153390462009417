<div [ngSwitch]="brand" class="credit-card-type-logo-wrapper">
  <img *ngSwitchCase="'visa'" src="/assets/logos/card-brands/visa.svg" />
  <img
    *ngSwitchCase="'mastercard'"
    src="/assets/logos/card-brands/mastercard.svg"
  />
  <img *ngSwitchCase="'amex'" src="/assets/logos/card-brands/amex.svg" />
  <img
    *ngSwitchCase="'discover'"
    src="/assets/logos/card-brands/discover.svg"
  />
  <img *ngSwitchCase="'jcb'" src="/assets/logos/card-brands/jcb.svg" />
  <span *ngSwitchDefault> {{ brand | titlecase }} </span>
</div>
