<div class="calendar-card" (click)="cardpress()">
  <div>
    <div class="calendar-card-header">
      <span class="calendar-card-title">{{ cal_title }}</span>
      <button mat-icon-button (click)="makeFavorite($event)">
        <mat-icon [ngClass]="{ gold: !toggle, goldoutlined: toggle }">{{
          !toggle ? "star" : "star_outline"
        }}</mat-icon>
      </button>
    </div>
    <div class="calendar-card-content">{{ cal_desc }}</div>
  </div>
  <div class="calendar-card-footer">
    <div class="copy-link-container">
      <button mat-raised-button (click)="copylink($event)" color="primary">
        Copy Link
      </button>
    </div>
  </div>
</div>
