import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public signUpForm: FormGroup;
  public isCreatingUser = false;

  constructor(private fb: FormBuilder, public auth: AuthService) {}

  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
    this.auth.isCreatingUser.asObservable().subscribe((state) => {
      this.isCreatingUser = state;
    });
  }

  public createUser() {
    const { email, password } = this.signUpForm.value;
    this.auth.emailSignUp(email, password);
  }
}
