<div class="favs">
  <div class="flex-container">
    <div class="words">Starred Calendars</div>
    <br />
    <div class="inner-flex-container">
      <div *ngFor="let card of GroupCalendar; let i = index">
        <div *ngIf="card.starred">
          <div class="fav-calendars">
            <div mat-button class="cal-card">
              <app-cal-card
                [cal_id]="card.id"
                [cal_title]="card.title"
                [cal_desc]="card.description"
                [cal_membs]="card.roomMembers"
                [cal_fav]="card.starred"
              ></app-cal-card>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div>
        <div class="words">All Calendars</div>
        <br />
        <div class="inner-flex-container">
          <div *ngFor="let card of GroupCalendar; let i = index">
            <div class="all-calendars">
              <div class="cal-card">
                <app-cal-card
                  [cal_id]="card.id"
                  [cal_title]="card.title"
                  [cal_desc]="card.description"
                  [cal_membs]="card.roomMembers"
                  [cal_fav]="card.starred"
                ></app-cal-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
