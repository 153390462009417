import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsuviBtnLrgComponent } from './components/asuvi-btn-lrg/asuvi-btn-lrg.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AsuviSectionHeaderComponent } from './components/asuvi-section-header/asuvi-section-header.component'; // a plugin
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsuviCheckboxComponent } from './components/asuvi-checkbox/asuvi-checkbox.component';
import { MatRippleModule } from '@angular/material/core';
import { CreditCardTypeComponent } from './components/credit-card-type/credit-card-type.component';

@NgModule({
  declarations: [
    AsuviBtnLrgComponent,
    LayoutComponent,
    AsuviSectionHeaderComponent,
    AsuviCheckboxComponent,
    CreditCardTypeComponent,
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    AsuviBtnLrgComponent,
    LayoutComponent,
    AsuviSectionHeaderComponent,
    AsuviCheckboxComponent,
    CreditCardTypeComponent,
  ],
})
export class SharedModule {}
