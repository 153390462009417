import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TestcomponentComponent } from './testcomponent/testcomponent.component';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: TestcomponentComponent,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'premium',
    loadChildren: () =>
      import('./premium/premium.module').then((m) => m.PremiumModule),
  },
  {
    path: 'group-cal',
    loadChildren: () =>
      import('./group-calendar/group-calendar.module').then(
        (m) => m.GroupCalendarModule
      ),
  },
  {
    path: 'dashboard',
    // component: DashboardComponent,
    // ...canActivate(redirectUnauthorizedToLogin),
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'my-cal',
    loadChildren: () =>
      import('./my-calendar/my-calendar.module').then(
        (m) => m.MyCalendarModule
      ),
  },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
