import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { CookieService } from 'ngx-cookie-service';
import { from, Subject } from 'rxjs';
import { first, mapTo, switchMap, take } from 'rxjs/operators';
import { addHours } from 'date-fns';
import { AuthCookies, AuthProviders } from '../models/auth';
import { AlertsService } from './alerts.service';
import { environment } from 'src/environments/environment';

declare const gapi: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //TODO: Try redirct sign in
  public isCreatingUser: Subject<boolean> = new Subject();
  public isLoggingIn: Subject<boolean> = new Subject();
  public providerChange: Subject<string> = new Subject();

  constructor(
    private afAuth: AngularFireAuth,
    private cookieService: CookieService,
    private afs: AngularFirestore,
    private router: Router,
    private alerts: AlertsService
  ) {}

  /**
   * Method to Sign In with Google (Includes new user)
   */
  public async googleSignIn(): Promise<void> {
    const googleUser = await this.gapiSignIn();
    if (!googleUser) return;
    const { id_token } = googleUser.getAuthResponse();
    const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
    const userCredential = await this.afAuth
      .signInWithCredential(credential)
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          this.alerts.showSnackbar(
            'An account with this email already exists. To link accounts please sign in first.'
          );
        } else if (error.code === 'auth/invalid-credential') {
          this.alerts.showSnackbar(
            'Credentials have expired, please sign in again.'
          );
        } else if (error.code === 'auth/operation-not-allowed') {
          this.alerts.showSnackbar(
            'This method of sign in is not currently supported. Please contact support if you believe this is a mistake.'
          );
        } else if (error.code === 'auth/user-disabled') {
          this.alerts.showSnackbar(
            'This account has been disabled. Please contact support if you believe this is a mistake.'
          );
        } else {
          this.alerts.showSnackbar(
            'Google Auth Error, please contact support.'
          );
          console.error('Sign-in with Credential (Google Auth) Error', error);
        }
      });
    if (!userCredential) return;
    if (userCredential.additionalUserInfo.isNewUser) {
      this.waitForUserCreation(
        userCredential.user,
        'Account Creation Error (Google)'
      );
    } else {
      // Will not sign into Microsoft for now
      this.router.navigate(['']);
    }
  }

  /**
   * Method to Sign In with Microsoft (Includes new user)
   */
  public async microsoftSignIn(): Promise<void> {
    const provider = this.getMicrosoftProvider();
    const userCredential = await this.afAuth
      .signInWithPopup(provider)
      .then(async (res) => {
        const credential = res.credential as firebase.auth.OAuthCredential;
        this.setMsalAccessToken(credential.accessToken);
        return res;
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          this.alerts.showSnackbar(
            'An account with this email already exists. To link accounts please sign in first.'
          );
        } else if (error.code === 'auth/cancelled-popup-request') {
          this.alerts.showSnackbar('Only one popup allowed at a time.');
        } else if (error.code === 'auth/operation-not-allowed') {
          this.alerts.showSnackbar(
            'This method of sign in is not currently supported. Please contact support if you believe this is a mistake.'
          );
        } else if (error.code === 'auth/popup-blocked') {
          this.alerts.showSnackbar(
            'The popup window was blocked by the browser.'
          );
        } else if (error.code === 'auth/popup-closed-by-user') {
          this.alerts.showSnackbar(
            'The popup window was closed. Please try again.'
          );
        } else {
          this.alerts.showSnackbar(
            'Microsoft Auth Error, please contact support.'
          );
          console.error('Sign-in with Popup (Microsoft Auth) Error', error);
        }
      });
    if (!userCredential) return;
    if (userCredential.additionalUserInfo.isNewUser) {
      this.waitForUserCreation(
        userCredential.user,
        'Account Creation Error (Microsoft)'
      );
    } else {
      this.router.navigate(['']);
    }
  }

  /**
   * Create a new user account with an email and password
   * @param email user's email address
   * @param password user's password (at least 6 characters)
   */
  public emailSignUp(email: string, password: string) {
    from(this.afAuth.createUserWithEmailAndPassword(email, password))
      .pipe(
        switchMap(({ user }) => this.metadataWatcher(user)),
        take(1),
        switchMap((user) => from(user.getIdToken(true)))
      )
      .subscribe(
        () => this.router.navigate(['']),
        (error) => {
          if (error.code === 'auth/email-already-in-use') {
            this.alerts.showSnackbar(
              'An account already exists with the given email address.'
            );
          } else if (error.code === 'auth/invalid-email') {
            this.alerts.showSnackbar('The provided email is not valid.');
          } else if (error.code === 'auth/weak-password') {
            this.alerts.showSnackbar(
              'The password is too weak. Please sign in again.'
            );
          } else {
            this.alerts.showSnackbar(
              'Email Sign Up Error, please contact support.'
            );
            console.error('Email Sign Up Error', error);
          }
        }
      );
  }

  /**
   * Log in to a user account with an email and password
   * @param email user's email address
   * @param password user's password (at least 6 characters)
   */
  public emailSignIn(email: string, password: string) {
    this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.router.navigate(['./dashboard']);
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          this.alerts.showSnackbar('The provided email is not valid.');
        } else if (error.code === 'auth/user-disabled') {
          this.alerts.showSnackbar(
            'This account has been disabled. Please contact support if you believe this is a mistake.'
          );
        } else if (error.code === 'auth/user-not-found') {
          this.alerts.showSnackbar('User not found. Create a new account.');
        } else if (error.code === 'auth/wrong-password') {
          this.alerts.showSnackbar(
            'Either the password or email is incorrect.'
          );
        } else {
          this.alerts.showSnackbar(
            'Email Sign Up Error, please contact support.'
          );
          console.error('Email Sign In Error', error);
        }
      });
  }

  /**
   * Link the current user to Google
   */
  public async linkWithGoogle(): Promise<void> {
    return this.afAuth.currentUser.then(async (user) => {
      const googleUser = await this.gapiSignIn();
      if (!googleUser) return;
      const { id_token } = googleUser.getAuthResponse();
      const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
      user
        .linkWithCredential(credential)
        .then(() => {
          this.providerChange.next('Google Linked');
        })
        .catch((error) => {
          if (error.code === 'auth/provider-already-linked') {
            this.alerts.showSnackbar(
              'This provider is already linked to your account.'
            );
          } else if (error.code === 'auth/invalid-credential') {
            this.alerts.showSnackbar(
              'Credentials have expired, please sign in again.'
            );
          } else if (error.code === 'auth/credential-already-in-use') {
            this.alerts.showSnackbar(
              'This user is already created or connected to an account.'
            );
          } else if (error.code === 'auth/email-already-in-use') {
            this.alerts.showSnackbar(
              'Email corresponding to this user is already in use.'
            );
          } else {
            this.alerts.showSnackbar(
              'Link with Google Error, please contact support.'
            );
            console.error('Email Sign Up Error', error);
          }
        });
    });
  }

  /**
   * Link the current user to Microsoft
   */
  public async linkWithMicrosoft() {
    return this.afAuth.currentUser.then((user) => {
      const provider = this.getMicrosoftProvider();
      user
        .linkWithPopup(provider)
        .then(async (res) => {
          const credential = res.credential as firebase.auth.OAuthCredential;
          this.setMsalAccessToken(credential.accessToken);
          this.providerChange.next('Microsoft Linked');
          return res;
        })
        .catch((error) => {
          if (error.code === 'auth/provider-already-linked') {
            this.alerts.showSnackbar(
              'This provider is already linked to your account.'
            );
          } else if (error.code === 'auth/credential-already-in-use') {
            this.alerts.showSnackbar(
              'This user is already created or connected to an account.'
            );
          } else if (error.code === 'auth/cancelled-popup-request') {
            this.alerts.showSnackbar('Only one popup allowed at a time.');
          } else if (error.code === 'auth/operation-not-allowed') {
            this.alerts.showSnackbar(
              'This method of sign in is not currently supported. Please contact support if you believe this is a mistake.'
            );
          } else if (error.code === 'auth/popup-blocked') {
            this.alerts.showSnackbar(
              'The popup window was blocked by the browser.'
            );
          } else if (error.code === 'auth/popup-closed-by-user') {
            this.alerts.showSnackbar(
              'The popup window was closed. Please try again.'
            );
          } else if (error.code === 'auth/email-already-in-use') {
            this.alerts.showSnackbar(
              'Email corresponding to this user is already in use.'
            );
          } else {
            this.alerts.showSnackbar(
              'Microsoft Auth Error, please contact support.'
            );
            console.error('Link with Popup (Microsoft Auth) Error', error);
          }
        });
    });
  }

  /**
   * Method to sign out of application, removes auth instances in msal and gapi
   */
  public signOut(): void {
    this.afAuth.signOut();
    const googleAuth = gapi.auth2.getAuthInstance();
    if (googleAuth.isSignedIn.get()) {
      googleAuth.signOut();
    }
    this.deleteMsalAccessToken();
    // TODO: navigate to landing page
    this.router.navigate(['']);
  }

  /**
   * Helper to create the Microsoft Provider
   */
  private getMicrosoftProvider() {
    const provider = new firebase.auth.OAuthProvider(AuthProviders.MICROSOFT);
    environment.microsoftGraph.scopes.forEach((scope) =>
      provider.addScope(scope)
    );
    return provider;
  }

  /**
   * Checks if the current account is linked with a provider
   * @param provider The provider domain
   */
  public async isLinkedWithProvider(provider: AuthProviders): Promise<boolean> {
    const user = await this.getCurrentUser();
    if (!user) return null;
    const providers = user.providerData;
    if (providers.filter((e) => e.providerId === provider).length > 0) {
      return true;
    }
    return false;
  }

  /**
   * Ensure that the Gapi Client is linked prior to api call
   */
  public async initGapiClient() {
    if (await this.isLinkedWithProvider(AuthProviders.GOOGLE)) {
      const googleUser = await this.gapiSignIn();
      if (googleUser) {
        console.log('Able to sign in to gapi', googleUser);
        return true;
      } else {
        console.log('Unable to sign in with gapi');
        return false;
      }
    } else {
      console.error(
        'initGapiClient failed, current user is not linked to Google Provider'
      );
      this.alerts.showSnackbar(
        'This user is not linked to a Google Provider, please link the accounts.'
      );
      return false;
    }
  }

  /**
   * Sign in with Google API, this is necessary to access Google Calendar API
   */
  private async gapiSignIn(): Promise<any> {
    const googleAuth = gapi.auth2.getAuthInstance();

    if (googleAuth.isSignedIn.get()) {
      return googleAuth.currentUser.get();
    }

    return await googleAuth.signIn().catch((error: any) => {
      if (error.error === 'popup_closed_by_user') {
        this.alerts.showSnackbar('Popup was closed, please try again.');
      } else if (error.error === 'access_denied') {
        this.alerts.showSnackbar(
          'Access denied to required scopes, some features may be disabled.'
        );
      } else if (error.error === 'immediate_failed') {
        this.alerts.showSnackbar(
          'Could not login to previous session. Please sign in again.'
        );
      } else {
        this.alerts.showSnackbar('Google Auth Error, please contact support.');
        console.error('GAPI Sign In Error', error);
      }
    });
  }

  /**
   * Get Msal Access Token for Graph API
   */
  private setMsalAccessToken(accessToken: string): void {
    if (!accessToken) {
      this.deleteMsalAccessToken;
    }
    this.cookieService.set(
      AuthCookies.MSAL,
      accessToken,
      addHours(new Date(), 1),
      '/' //TODO: Enable strict instead of default LAX, uncommenting the last 3 lines does not work
      // undefined,
      // true,
      // 'Strict'
    );
  }

  /**
   * Deletes the MSAL Access Token
   */
  private deleteMsalAccessToken(): void {
    this.cookieService.delete(AuthCookies.MSAL, '/');
  }

  /**
   * Fetch the current MSAL Access Token, if expired re-authenticate to recieve updated access token
   * Note: If this method is not called by a user event (ex. button click) modern browers will block
   * the re-authenticate popup window by default.
   */
  public async getMsalAccessToken(): Promise<string> {
    let accessToken = this.cookieService.get(AuthCookies.MSAL);
    if (accessToken) {
      return accessToken;
    }
    if (!(await this.isLinkedWithProvider(AuthProviders.MICROSOFT))) {
      throw new Error(
        'Get Msal Access Token Error: The current user does not have a linked provider to microsoft'
      );
    }
    const provider = this.getMicrosoftProvider();
    return (await (await this.afAuth.currentUser)
      .reauthenticateWithPopup(provider)
      .then(async (res) => {
        const credential = res.credential as firebase.auth.OAuthCredential;
        this.setMsalAccessToken(credential.accessToken);
        return credential.accessToken;
      })
      .catch((error) => {
        if (error.code === 'auth/cancelled-popup-request') {
          this.alerts.showSnackbar('Only one popup allowed at a time.');
        } else if (error.code === 'auth/user-mismatch') {
          this.alerts.showSnackbar(
            'The user does not match the current user, please log out and sign in.'
          );
        } else if (error.code === 'auth/operation-not-allowed') {
          this.alerts.showSnackbar(
            'This method of sign in is not currently supported. Please contact support if you believe this is a mistake.'
          );
        } else if (error.code === 'auth/popup-blocked') {
          // Important: By default the browser will block any popups not initiated by a user
          this.alerts.showSnackbar(
            'The popup window was blocked by the browser.'
          );
        } else if (error.code === 'auth/popup-closed-by-user') {
          this.alerts.showSnackbar(
            'The popup window was closed. Please try again.'
          );
        } else {
          this.alerts.showSnackbar(
            'Microsoft Auth Error, please contact support.'
          );
          console.error('Re-Auth with Popup (Microsoft Auth) Error', error);
        }
      })) as string;
  }

  /**
   * Fetch the current signed in user, may deprecate soon
   */
  public async getCurrentUser(): Promise<firebase.User> {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });
    });
  }

  /**
   * Pending claims and DB write operatation from backend
   * @param user New user object
   * @param errorMsg Optional leading error message
   */
  private waitForUserCreation(user: firebase.User, errorMsg?: string) {
    this.isCreatingUser.next(true);
    return this.metadataWatcher(user)
      .pipe(
        take(1),
        switchMap((user) => from(user.getIdToken(true)))
      )
      .subscribe(
        () => {
          this.router.navigate(['']);
        },
        (error) => {
          this.isCreatingUser.next(false);
          console.error(errorMsg, error);
        }
      );
  }

  /**
   * For new user registration
   * the refreshTime field will be changed once custom claims and user object is written to DB
   * @param user new user object
   */
  private metadataWatcher(user: firebase.User) {
    return this.afs
      .collection('users')
      .doc(user.uid)
      .valueChanges()
      .pipe(
        first((user) => !!user),
        mapTo(user)
      );
  }
}
