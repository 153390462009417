<app-layout>
  <div class="dashboard-layout">
    <div class="dashboard-container">
      <div class="dashboard-navigiation-container">
        <ul>
          <button
            class="new-button"
            mat-raised-button
            color="primary"
            (click)="showDialog()"
          >
            New
          </button>
          <br />
          <br />
          <div>
            <a routerLinkActive="dashboard-link-active">My Calendars</a>
          </div>
          <br />
          <div>
            <a routerLinkActive="dashboard-link-active" routerLink="/calendars"
              >Group Calendars</a
            >
          </div>
          <br />
          <div>
            <a
              routerLinkActive="dashboard-link-active"
              routerLink="/appointments"
              >Appointments</a
            >
          </div>
          <br />
          <div>
            <a routerLinkActive="dashboard-link-active">Polls</a>
          </div>
        </ul>
      </div>
      <div class="dashboard-content-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</app-layout>
