import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { CalCardComponent } from './components/cal-card/cal-card.component';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: CalendarsComponent,
      },
      {
        path: 'calendars',
        component: CalendarsComponent,
      },
      {
        path: 'appointments',
        component: AppointmentsComponent,
      },
      {
        path: 'components/cal-card',
        component: CalCardComponent,
      },
      {
        path: 'components/dialog-box',
        component: DialogBoxComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
