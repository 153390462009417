import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-asuvi-btn-lrg',
  templateUrl: './asuvi-btn-lrg.component.html',
  styleUrls: ['./asuvi-btn-lrg.component.scss'],
})
export class AsuviBtnLrgComponent implements OnInit {
  @Input()
  public outline: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public imgSrc: string;

  @Output()
  private click = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  public handleButtonClick(event: Event) {
    if (this.disabled) {
      return;
    }
    this.click.emit(event);
  }
}
