import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.scss'],
})
export class CalendarsComponent implements OnInit {
  GroupCalendar: any;

  calendarRooms = [
    {
      id: 'Identification',
      title: 'Friends',
      description: 'Personal calendar for friend events',
      starred: true,
      roomMembers: ['Stacey', 'John', 'Adam'],
    },
    {
      id: 'Identification2',
      title: 'Friends2',
      description: 'Personal calendar for 222',
      starred: true,
      roomMembers: ['Stacey222', 'John222', 'Adam222'],
    },
    {
      id: 'Identification3',
      title: 'Friends3',
      description: 'Personal calendar for 333',
      starred: false,
      roomMembers: ['Stacey333', 'John333', 'Adam333'],
    },
    {
      id: 'Identification3',
      title: 'Friends3',
      description: 'Personal calendar for 333',
      starred: false,
      roomMembers: ['Stacey333', 'John333', 'Adam333'],
    },
    {
      id: 'Identification3',
      title: 'Friends3',
      description: 'Personal calendar for 333',
      starred: true,
      roomMembers: ['Stacey333', 'John333', 'Adam333'],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.createCardsList();
    this.ViewCalendar();
  }

  createCardsList() {}

  ViewCalendar() {
    this.GroupCalendar = this.calendarRooms;
    console.log(this.calendarRooms[1].id);
  }
}
