import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-card-type',
  templateUrl: './credit-card-type.component.html',
  styleUrls: ['./credit-card-type.component.scss'],
})
export class CreditCardTypeComponent implements OnInit {
  // https://stripe.com/docs/card-brand-choice#card-brands
  @Input()
  public brand:
    | 'amex'
    | 'cartes_bancaires'
    | 'diners_club'
    | 'discover'
    | 'jcb'
    | 'mastercard'
    | 'visa'
    | 'unionpay';

  constructor() {}

  ngOnInit(): void {}
}
