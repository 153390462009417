import { Component, OnInit } from '@angular/core';
import { QueryRef } from 'apollo-angular';
// import { MyQueryGQL, MyQueryQuery } from 'src/generated/graphql';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MicrosoftCalendarService } from '../services/calendar-providers/microsoft-calendar.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { GoogleCalendarService } from '../services/calendar-providers/google-calendar.service';
import { addMonths, addWeeks } from 'date-fns';
import { CalendarSyncService } from '../services/calendar-sync.service';

@Component({
  selector: 'app-testcomponent',
  templateUrl: './testcomponent.component.html',
  styleUrls: ['./testcomponent.component.scss'],
})
export class TestcomponentComponent implements OnInit {
  // queryRef: QueryRef<MyQueryQuery>;
  calendars$: any;

  constructor(
    // private query: MyQueryGQL,
    public auth: AuthService,
    private outlook: MicrosoftCalendarService,
    private gCal: GoogleCalendarService,
    private afAuth: AngularFireAuth,
    private calSync: CalendarSyncService
  ) {}

  ngOnInit(): void {
    // this.queryRef = this.query.watch();
    // this.calendars$ = this.queryRef.valueChanges.pipe(
    //   map((res) => res.data.Calendar)
    // );

    // this.calendars$.subscribe((val) => console.log(val));

    this.afAuth.onAuthStateChanged((res) => {
      console.log(res);
      res.getIdTokenResult().then((res) => console.log(res));
    });
  }

  async testButton() {
    this.calSync.syncAllEvents();
    //id, provider, user_id
  }

  async testGapi() {
    this.calSync.syncCalendars();
  }
}
