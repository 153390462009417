import { parse, startOfDay } from 'date-fns';
import {
  CalendarProvidersEnum,
  EventsInsertInput,
  EventStatusEnum,
} from 'src/generated/graphql';
import {
  IEventResource,
  IGCalAttendees,
  IGCalResponseStatus,
} from '../calendar-providers/models/google-calendar';
import {
  IOutlookAttendee,
  IOutlookEventResource,
  IOutlookRecipient,
  IOutlookResponseStatus,
} from '../calendar-providers/models/outlook-calendar';

// TODO track event deletion (incremental change), google date as isostring

export function formatGEvents(events: IEventResource[], calendarId: string) {
  const formated = events.map((event) => {
    const {
      iCalUID,
      start,
      end,
      recurringEventId,
      created,
      summary,
      description,
      location,
      id,
      status,
      organizer,
      creator,
      attendees,
    } = event;
    let allDay = false;
    if (start.date && end.date) {
      allDay = true;
    }
    return {
      iCalUID,
      allDay,
      startTime: allDay
        ? startOfDay(parse(start.date, 'yyyy-MM-dd', new Date())).toISOString()
        : start.dateTime,
      endTime: allDay
        ? startOfDay(parse(end.date, 'yyyy-MM-dd', new Date())).toISOString()
        : end.dateTime,
      startDate: start.date,
      endDate: end.date,
      timezone: start.timeZone
        ? start.timeZone
        : Intl.DateTimeFormat().resolvedOptions().timeZone, // use local timezone if not specified (allDay)
      recurrenceRule: null, // since recurring events are expanded
      recurringEventId,
      isDetached: false, //TODO: Track deteched calendars?
      createdAt: created,
      title: summary,
      description,
      url: null, //TODO: Track URL?
      location,
      notes: null, //TODO: track notes?
      status,
      provider: CalendarProvidersEnum.Goog,
      id,
      calendarId,
      deleted: status === 'cancelled' ? true : false,
      organizer, //TODO: Track organizers, attendees, and creator
      creator,
      attendees,
    };
  });
  return formated as EventsInsertInput[];
}

export function formatOEvents(events: IOutlookEventResource[], calId: string) {
  const formatted = events.map((event) => {
    const {
      iCalUId,
      isAllDay,
      start,
      end,
      createdDateTime,
      subject,
      bodyPreview,
      location,
      responseStatus,
      id,
      isCancelled,
      organizer,
      isOrganizer,
      attendees,
      originalStartTimeZone,
    } = event;
    return {
      iCalUID: iCalUId,
      allDay: isAllDay,
      startTime: start.dateTime,
      endTime: end.dateTime,
      startDate: isAllDay ? start.dateTime : null,
      endDate: isAllDay ? end.dateTime : null,
      // https://docs.microsoft.com/en-us/graph/api/outlookuser-supportedtimezones?view=graph-rest-1.0&tabs=http
      timezone: originalStartTimeZone
        ? originalStartTimeZone
        : Intl.DateTimeFormat().resolvedOptions().timeZone, // use local timezone if not specified (allDay)
      recurrenceRule: null, // since recurring events are expanded
      recurringEventId: event.seriesMasterId,
      isDetached: event.type === 'exception', //TODO: Track deteched calendars?
      createdAt: createdDateTime,
      title: subject,
      description: bodyPreview ? bodyPreview : null,
      url: null, //TODO: Track URL?
      location: location.displayName ? location.displayName : null, //TODO: Better track location(s) [lat, long]?
      notes: null, //TODO: track notes?
      status: convertOutlookResponse(responseStatus, isCancelled),
      provider: CalendarProvidersEnum.Msft,
      id,
      calendarId: calId,
      deleted: isCancelled,
      organizer: convertOutlookRecipient(organizer, isOrganizer),
      creator: convertOutlookRecipient(organizer, isOrganizer), // No equivalent in Outlook?
      attendees: convertOutlookAttendees(attendees, organizer),
    };
  });
  return formatted as EventsInsertInput[];
}

function convertOutlookResponse(
  responseStatus: IOutlookResponseStatus,
  isCancelled: boolean
) {
  const { response } = responseStatus;
  if (response === 'Declined' || isCancelled) {
    return EventStatusEnum.Cancelled;
  } else if (response === 'TentativelyAccepted') {
    return EventStatusEnum.Tentative;
  } else {
    return EventStatusEnum.Confirmed;
  }
}

function convertOutlookStatus(
  responseStatus: IOutlookResponseStatus
): IGCalResponseStatus {
  const { response } = responseStatus;
  switch (response) {
    case 'Accepted':
      return 'accepted';
    case 'Declined':
      return 'declined';
    case 'None':
      return 'needsAction';
    case 'NotResponded':
      return 'needsAction';
    case 'Organizer':
      return 'accepted';
    case 'TentativelyAccepted':
      return 'tentative';
    default:
      console.error('Received unknown outlook response status', response);
      return 'accepted';
  }
}

function convertOutlookRecipient(
  organizer: IOutlookRecipient,
  isOrganizer: boolean
) {
  return {
    email: organizer.emailAddress.name,
    displayName: organizer.emailAddress.name,
    self: isOrganizer,
  };
}

function convertOutlookAttendeeType(
  type: 'required' | 'optional' | 'resource'
) {
  if (type === 'required') {
    return { optional: false };
  } else if (type === 'optional') {
    return { optional: true };
  } else if (type === 'resource') {
    return { resource: true };
  } else {
    return {};
  }
}

function convertOutlookAttendees(
  attendees: IOutlookAttendee[],
  organizer: IOutlookRecipient
): IGCalAttendees[] {
  if (attendees.length === 0) {
    return null;
  }
  const formattedAttendees = attendees.map((attendee) => {
    const { emailAddress, status, type } = attendee;
    const { optional, resource } = convertOutlookAttendeeType(type);
    // TODO: Missing 'self'
    return {
      email: emailAddress.address,
      displayName: emailAddress.name,
      responseStatus: convertOutlookStatus(status),
      optional,
      resource,
      organizer:
        organizer.emailAddress.address === emailAddress.address ? true : null,
    };
  });
  return formattedAttendees;
}
