<div class="signup-image-section">
  <img src="assets/team_meeting_computer.svg" />
</div>

<div class="signup-form-section">
  <div class="signup-form-container" *ngIf="!isCreatingUser">
    <div class="signup-form">
      <h1>New here?</h1>
      <app-asuvi-btn-lrg
        (click)="auth.googleSignIn()"
        imgSrc="/assets/google_logo.svg"
      >
        Sign up with Google
      </app-asuvi-btn-lrg>
      <app-asuvi-btn-lrg
        (click)="auth.microsoftSignIn()"
        [outline]="true"
        imgSrc="/assets/microsoft_logo.svg"
      >
        Sign up with Microsoft
      </app-asuvi-btn-lrg>
      <div class="horizontal-line-text">
        <span>Or</span>
      </div>
    </div>
    <form [formGroup]="signUpForm" class="signup-form">
      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input
          matInput
          formControlName="email"
          type="email"
          name="email"
          autocomplete="email"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input
          matInput
          formControlName="password"
          type="password"
          name="new-password"
          autocomplete="new-password"
          placeholder="8+ Characters"
        />
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="createUser()"
        class="mat-btn-lrg"
      >
        Create account
      </button>

      <span class="signup-disclaimer-text">
        By signing up you agree with Asuvi’s
        <a class="asuvi-accent" href="https://asuvi.com/privacy"
          >Privacy Policy
        </a>
        and
        <a class="asuvi-accent" href="https://asuvi.com/terms">
          Terms & Conditions</a
        >.
      </span>
      <br />
      <span class="signup-disclaimer-text">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </span>

      <p class="signup-login-text">
        Already have an account?
        <a class="asuvi-accent" routerLink="/login">Log in</a>
      </p>
    </form>
  </div>
  <div class="signup-form-container" *ngIf="isCreatingUser">
    <div class="signup-form">
      <h1>Give us a second while we create your account!</h1>
    </div>
  </div>
</div>
