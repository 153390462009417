import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cal-card',
  templateUrl: './cal-card.component.html',
  styleUrls: ['./cal-card.component.scss'],
})
export class CalCardComponent implements OnInit {
  @Input() cal_id: '';
  @Input() cal_title: '';
  @Input() cal_desc: '';
  @Input() cal_membs: [];
  @Input() cal_fav: '';

  constructor() {}
  toggle = true;
  favorite = false;
  status = 'Enable';

  ngOnInit(): void {}

  makeFavorite(event: Event) {
    event.stopPropagation();
    if (this.toggle) {
      console.log('Added to favorites!!');
      this.favorite = true;
    } else console.log('Removed from favorites');
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
  }

  copylink(event: Event) {
    event.stopPropagation();
    console.log('Copy link pressed');
  }
  cardpress() {
    console.log('card press works!');
  }
}
