<div class="login-image-section">
  <img src="assets/team_meeting_computer.svg" />
</div>
<div class="login-form-section">
  <div class="login-form-container" *ngIf="!isLoggingIn">
    <div class="login-form">
      <h1>Welcome back.</h1>
      <app-asuvi-btn-lrg
        (click)="auth.googleSignIn()"
        imgSrc="/assets/google_logo.svg"
      >
        Login In with Google
      </app-asuvi-btn-lrg>
      <app-asuvi-btn-lrg
        (click)="auth.microsoftSignIn()"
        [outline]="true"
        imgSrc="/assets/microsoft_logo.svg"
      >
        Login In with Microsoft
      </app-asuvi-btn-lrg>
      <div class="horizontal-line-text">
        <span>Or</span>
      </div>
    </div>
    <form [formGroup]="loginForm" class="login-form">
      <mat-form-field>
        <mat-label>Your email</mat-label>
        <input
          matInput
          formControlName="email"
          type="email"
          name="email"
          autocomplete="email"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input
          matInput
          formControlName="password"
          type="password"
          name="current-password"
          autocomplete="current-password"
        />
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="loginUser()"
        class="mat-btn-lrg"
      >
        Login
      </button>
      <span class="login-disclaimer-text">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </span>

      <p class="login-login-text">
        New here?
        <a class="asuvi-accent" routerLink="/signup">Sign Up</a>
      </p>
    </form>
  </div>
  <!-- <div class="login-form-container" *ngIf="isLoggingIn">
    <div class="login-form">
      <h1>Give us a second while we log you in!</h1>
    </div>
  </div> -->
</div>
