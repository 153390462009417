import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const gapi: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'Asuvi-Web';

  ngAfterViewInit(): void {
    this.gapiInit();
  }

  private gapiInit(): void {
    gapi.load('client', () => {
      gapi.client.init(environment.googleCal).then(
        () => console.debug('GAPI client successfully initialized'),
        (error: any) => console.error('Could not initialize GAPI', error)
      );
      gapi.client.load('calendar', 'v3');
    });
  }
}
