import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import {
  ICalendarListResource,
  IEventResource,
  IGetGCalEventsResponse,
  IGetGCalReponse,
} from './models/google-calendar';

declare const gapi: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleCalendarService {
  constructor(private auth: AuthService) {}

  /**
   * Returns Google Calendars, if syncToken is specified, will return changes
   * @param syncToken Token obtained from the nextSyncToken field returned by previous call. Optional.
   * @param pageToken Token specifying which result page to return (Should be null)
   * @param maxResults Maximum number of entries returned on one call. Default 100 entries. Max 250. Optional.
   * @param showDeleted Whether to include deleted calendar list entries in the result. The default is False. Optional.
   * @param showHidden Whether to show hidden entries. The default is False. Optional.
   * See More: https://developers.google.com/calendar/v3/reference/calendarList/list
   */
  async getCalendars(
    syncToken?: string,
    pageToken?: string,
    maxResults?: number,
    showDeleted?: boolean,
    showHidden?: boolean
  ): Promise<IGetGCalReponse> {
    if (await this.auth.initGapiClient()) {
      let calendarsRes: ICalendarListResource[] = [];
      let nextSyncTokenRes = '';
      try {
        const { result } = await gapi.client.calendar.calendarList.list({
          syncToken,
          pageToken,
          maxResults,
          showDeleted,
          showHidden,
        });

        if (result.nextPageToken) {
          const { calendars, nextSyncToken } = await this.getCalendars(
            null,
            result.nextPageToken,
            maxResults,
            showDeleted,
            showHidden
          );
          calendarsRes = calendarsRes.concat(calendars);
          nextSyncTokenRes = nextSyncToken;
        } else {
          nextSyncTokenRes = result.nextSyncToken;
        }
        calendarsRes = calendarsRes.concat(result.items);
        return { calendars: calendarsRes, nextSyncToken: nextSyncTokenRes };
      } catch (err) {
        if (err.status === 410) {
          console.error('Invalid sync token, clear calendar store and resync.');
          const { calendars, nextSyncToken } = await this.getCalendars();
          return { calendars, nextSyncToken, cleanStore: true };
        } else {
          console.error(err);
        }
        return { calendars: [], error: 'Error when retrieving calendars' };
      }
    } else {
      return { calendars: [], error: 'Error, not signed in with Google' };
    }
  }

  /**
   * Returns events on a specified calendar. If syncToken is specified, will return changes
   * @param calendarId Calendar identifier
   * @param syncToken Token obtained from the nextSyncToken field returned by previous call. Optional.
   * @param pageToken Token specifying which result page to return (Should be null)
   * @param maxResults Maximum number of entries returned on one call. Default 250 entries. Max 2500. Optional.
   * @param timeMin Lower bound (exclusive) for an event's start time to filter by. Optional. RFC3339 ISOString
   * @param timeMax Upper bound (exclusive) for an event's end time to filter by. Optional.
   * @param singleEvents Whether to expand recurring events into instances and only return single one-off events
   * @param showDeleted Whether to include deleted events (with status equals "cancelled") in the result.
   * @param showHidden Whether to include hidden invitations in the result. Optional. The default is False.
   * See More: https://developers.google.com/calendar/v3/reference/events/list
   */
  async getEvents(
    calendarId: string,
    syncToken?: string,
    pageToken?: string,
    maxResults?: number,
    timeMin?: Date,
    timeMax?: Date,
    singleEvents?: boolean,
    showDeleted?: boolean,
    showHiddenInvitations?: boolean
  ): Promise<IGetGCalEventsResponse> {
    if (await this.auth.initGapiClient()) {
      let eventRes: IEventResource[] = [];
      let nextSyncTokenRes = '';
      try {
        const { result } = await gapi.client.calendar.events.list({
          calendarId,
          syncToken,
          pageToken,
          maxResults,
          timeMin: timeMin ? timeMin.toISOString() : null,
          timeMax: timeMax ? timeMax.toISOString() : null,
          singleEvents,
          showDeleted,
          showHiddenInvitations,
        });

        if (result.nextPageToken) {
          const { events, nextSyncToken } = await this.getEvents(
            calendarId,
            null,
            result.nextPageToken,
            maxResults,
            timeMin,
            timeMax,
            singleEvents,
            showDeleted,
            showHiddenInvitations
          );
          eventRes = eventRes.concat(events);
          nextSyncTokenRes = nextSyncToken;
        } else {
          nextSyncTokenRes = result.nextSyncToken;
        }
        eventRes = eventRes.concat(result.items);
        return { events: eventRes, nextSyncToken: nextSyncTokenRes };
      } catch (err) {
        if (err.status === 410) {
          console.error('Invalid sync token, clear event store and resync.');
          const { events, nextSyncToken } = await this.getEvents(
            calendarId,
            null,
            null,
            null,
            timeMin,
            timeMax,
            true,
            true
          );
          return { events, nextSyncToken, cleanStore: true };
        } else {
          console.error(err);
        }
        return { events: [], error: 'Error when retrieving calendar events' };
      }
    } else {
      return { events: [], error: 'Error, not signed in with Google' };
    }
  }

  /**
   * Returns instances of the specified recurring event. Note: syncToken is useless in this operation
   * @param calendarId Calendar identifier
   * @param eventId Recurring event identifier.
   * @param syncToken Token obtained from the nextSyncToken field returned by previous call. Optional.
   * @param pageToken Token specifying which result page to return (Should be null)
   * @param maxResults Maximum number of entries returned on one call. Default 250 entries. Max 2500. Optional.
   * @param timeMin Lower bound (exclusive) for an event's start time to filter by. Optional. RFC3339 ISOString
   * @param timeMax Upper bound (exclusive) for an event's end time to filter by. Optional.
   * @param showDeleted Whether to include deleted events (with status equals "cancelled") in the result.
   * See More: https://developers.google.com/calendar/v3/reference/events/instances
   */
  async getInstances(
    calendarId: string,
    eventId: string,
    syncToken?: string,
    pageToken?: string,
    maxResults?: number,
    timeMin?: Date,
    timeMax?: Date,
    showDeleted?: boolean
  ) {
    if (await this.auth.initGapiClient()) {
      let eventRes: IEventResource[] = [];
      let nextSyncTokenRes = '';
      try {
        const { result } = await gapi.client.calendar.events.instances({
          calendarId,
          eventId,
          syncToken,
          pageToken,
          maxResults,
          timeMin: timeMin ? timeMin.toISOString() : null,
          timeMax: timeMax ? timeMax.toISOString() : null,
          showDeleted,
        });

        if (result.nextPageToken) {
          const { events, nextSyncToken } = await this.getInstances(
            calendarId,
            eventId,
            null,
            result.nextPageToken,
            maxResults,
            timeMin,
            timeMax,
            showDeleted
          );
          eventRes = eventRes.concat(events);
          nextSyncTokenRes = nextSyncToken;
        } else {
          nextSyncTokenRes = result.nextSyncToken;
        }
        eventRes = eventRes.concat(result.items);
        return { events: eventRes, nextSyncToken: nextSyncTokenRes };
      } catch (err) {
        if (err.status === 410) {
          console.error('Invalid sync token, clear event store and resync.');
          const { events, nextSyncToken } = await this.getInstances(
            calendarId,
            eventId
          );
          return { events, nextSyncToken, cleanStore: true };
        } else {
          console.error(err);
        }
        return { events: [], error: 'Error when retrieving calendar events' };
      }
    } else {
      return { events: [], error: 'Error, not signed in with Google' };
    }
  }
}
