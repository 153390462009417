import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  json: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
}



/** columns and relationships of "Asuvi_Roles" */
export interface AsuviRoles {
  __typename?: 'Asuvi_Roles';
  description: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "Asuvi_Roles" */
export interface AsuviRolesAggregate {
  __typename?: 'Asuvi_Roles_aggregate';
  aggregate?: Maybe<AsuviRolesAggregateFields>;
  nodes: Array<AsuviRoles>;
}

/** aggregate fields of "Asuvi_Roles" */
export interface AsuviRolesAggregateFields {
  __typename?: 'Asuvi_Roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AsuviRolesMaxFields>;
  min?: Maybe<AsuviRolesMinFields>;
}


/** aggregate fields of "Asuvi_Roles" */
export interface AsuviRolesAggregateFieldsCountArgs {
  columns?: Maybe<Array<AsuviRolesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Asuvi_Roles" */
export interface AsuviRolesAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<AsuviRolesMaxOrderBy>;
  min?: Maybe<AsuviRolesMinOrderBy>;
}

/** input type for inserting array relation for remote table "Asuvi_Roles" */
export interface AsuviRolesArrRelInsertInput {
  data: Array<AsuviRolesInsertInput>;
  on_conflict?: Maybe<AsuviRolesOnConflict>;
}

/** Boolean expression to filter rows from the table "Asuvi_Roles". All fields are combined with a logical 'AND'. */
export interface AsuviRolesBoolExp {
  _and?: Maybe<Array<Maybe<AsuviRolesBoolExp>>>;
  _not?: Maybe<AsuviRolesBoolExp>;
  _or?: Maybe<Array<Maybe<AsuviRolesBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "Asuvi_Roles" */
export enum AsuviRolesConstraint {
  /** unique or primary key constraint */
  AsuviRolesPkey = 'Asuvi_Roles_pkey'
}

export enum AsuviRolesEnum {
  /** The co-organizer has the same privileges as the organizer */
  CoOrganizer = 'co_organizer',
  /** A member that can contribute to a resource */
  Member = 'member',
  /** The creator of a resource */
  Organizer = 'organizer',
  /** A viewer can see a resource, but not contribute */
  Viewer = 'viewer'
}

/** expression to compare columns of type Asuvi_Roles_enum. All fields are combined with logical 'AND'. */
export interface AsuviRolesEnumComparisonExp {
  _eq?: Maybe<AsuviRolesEnum>;
  _in?: Maybe<Array<AsuviRolesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<AsuviRolesEnum>;
  _nin?: Maybe<Array<AsuviRolesEnum>>;
}

/** input type for inserting data into table "Asuvi_Roles" */
export interface AsuviRolesInsertInput {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface AsuviRolesMaxFields {
  __typename?: 'Asuvi_Roles_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "Asuvi_Roles" */
export interface AsuviRolesMaxOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface AsuviRolesMinFields {
  __typename?: 'Asuvi_Roles_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "Asuvi_Roles" */
export interface AsuviRolesMinOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Asuvi_Roles" */
export interface AsuviRolesMutationResponse {
  __typename?: 'Asuvi_Roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<AsuviRoles>;
}

/** input type for inserting object relation for remote table "Asuvi_Roles" */
export interface AsuviRolesObjRelInsertInput {
  data: AsuviRolesInsertInput;
  on_conflict?: Maybe<AsuviRolesOnConflict>;
}

/** on conflict condition type for table "Asuvi_Roles" */
export interface AsuviRolesOnConflict {
  constraint: AsuviRolesConstraint;
  update_columns: Array<AsuviRolesUpdateColumn>;
  where?: Maybe<AsuviRolesBoolExp>;
}

/** ordering options when selecting data from "Asuvi_Roles" */
export interface AsuviRolesOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Asuvi_Roles" */
export interface AsuviRolesPkColumnsInput {
  value: Scalars['String'];
}

/** select columns of table "Asuvi_Roles" */
export enum AsuviRolesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Asuvi_Roles" */
export interface AsuviRolesSetInput {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "Asuvi_Roles" */
export enum AsuviRolesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export interface BooleanComparisonExp {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
}

/**
 * Missing: created_at
 * 
 * 
 * columns and relationships of "Calendar"
 */
export interface Calendar {
  __typename?: 'Calendar';
  /** An array relationship */
  Events: Array<Events>;
  /** An aggregated array relationship */
  Events_aggregate: EventsAggregate;
  canEdit: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastSynced?: Maybe<Scalars['timestamptz']>;
  primary: Scalars['Boolean'];
  provider: CalendarProvidersEnum;
  sync: Scalars['Boolean'];
  syncToken?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['String'];
  uuid: Scalars['uuid'];
}


/**
 * Missing: created_at
 * 
 * 
 * columns and relationships of "Calendar"
 */
export interface CalendarEventsArgs {
  distinct_on?: Maybe<Array<EventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventsOrderBy>>;
  where?: Maybe<EventsBoolExp>;
}


/**
 * Missing: created_at
 * 
 * 
 * columns and relationships of "Calendar"
 */
export interface CalendarEventsAggregateArgs {
  distinct_on?: Maybe<Array<EventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventsOrderBy>>;
  where?: Maybe<EventsBoolExp>;
}

/** columns and relationships of "Calendar_Providers" */
export interface CalendarProviders {
  __typename?: 'Calendar_Providers';
  description: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "Calendar_Providers" */
export interface CalendarProvidersAggregate {
  __typename?: 'Calendar_Providers_aggregate';
  aggregate?: Maybe<CalendarProvidersAggregateFields>;
  nodes: Array<CalendarProviders>;
}

/** aggregate fields of "Calendar_Providers" */
export interface CalendarProvidersAggregateFields {
  __typename?: 'Calendar_Providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CalendarProvidersMaxFields>;
  min?: Maybe<CalendarProvidersMinFields>;
}


/** aggregate fields of "Calendar_Providers" */
export interface CalendarProvidersAggregateFieldsCountArgs {
  columns?: Maybe<Array<CalendarProvidersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Calendar_Providers" */
export interface CalendarProvidersAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<CalendarProvidersMaxOrderBy>;
  min?: Maybe<CalendarProvidersMinOrderBy>;
}

/** input type for inserting array relation for remote table "Calendar_Providers" */
export interface CalendarProvidersArrRelInsertInput {
  data: Array<CalendarProvidersInsertInput>;
  on_conflict?: Maybe<CalendarProvidersOnConflict>;
}

/** Boolean expression to filter rows from the table "Calendar_Providers". All fields are combined with a logical 'AND'. */
export interface CalendarProvidersBoolExp {
  _and?: Maybe<Array<Maybe<CalendarProvidersBoolExp>>>;
  _not?: Maybe<CalendarProvidersBoolExp>;
  _or?: Maybe<Array<Maybe<CalendarProvidersBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "Calendar_Providers" */
export enum CalendarProvidersConstraint {
  /** unique or primary key constraint */
  CalendarProvidersPkey = 'Calendar_Providers_pkey'
}

export enum CalendarProvidersEnum {
  /** Native Asuvi Calendar */
  Asvi = 'ASVI',
  /** Google Calendar */
  Goog = 'GOOG',
  /** Microsoft/Outlook Calendar */
  Msft = 'MSFT'
}

/** expression to compare columns of type Calendar_Providers_enum. All fields are combined with logical 'AND'. */
export interface CalendarProvidersEnumComparisonExp {
  _eq?: Maybe<CalendarProvidersEnum>;
  _in?: Maybe<Array<CalendarProvidersEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<CalendarProvidersEnum>;
  _nin?: Maybe<Array<CalendarProvidersEnum>>;
}

/** input type for inserting data into table "Calendar_Providers" */
export interface CalendarProvidersInsertInput {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface CalendarProvidersMaxFields {
  __typename?: 'Calendar_Providers_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "Calendar_Providers" */
export interface CalendarProvidersMaxOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface CalendarProvidersMinFields {
  __typename?: 'Calendar_Providers_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "Calendar_Providers" */
export interface CalendarProvidersMinOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Calendar_Providers" */
export interface CalendarProvidersMutationResponse {
  __typename?: 'Calendar_Providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<CalendarProviders>;
}

/** input type for inserting object relation for remote table "Calendar_Providers" */
export interface CalendarProvidersObjRelInsertInput {
  data: CalendarProvidersInsertInput;
  on_conflict?: Maybe<CalendarProvidersOnConflict>;
}

/** on conflict condition type for table "Calendar_Providers" */
export interface CalendarProvidersOnConflict {
  constraint: CalendarProvidersConstraint;
  update_columns: Array<CalendarProvidersUpdateColumn>;
  where?: Maybe<CalendarProvidersBoolExp>;
}

/** ordering options when selecting data from "Calendar_Providers" */
export interface CalendarProvidersOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Calendar_Providers" */
export interface CalendarProvidersPkColumnsInput {
  value: Scalars['String'];
}

/** select columns of table "Calendar_Providers" */
export enum CalendarProvidersSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Calendar_Providers" */
export interface CalendarProvidersSetInput {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "Calendar_Providers" */
export enum CalendarProvidersUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** aggregated selection of "Calendar" */
export interface CalendarAggregate {
  __typename?: 'Calendar_aggregate';
  aggregate?: Maybe<CalendarAggregateFields>;
  nodes: Array<Calendar>;
}

/** aggregate fields of "Calendar" */
export interface CalendarAggregateFields {
  __typename?: 'Calendar_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CalendarMaxFields>;
  min?: Maybe<CalendarMinFields>;
}


/** aggregate fields of "Calendar" */
export interface CalendarAggregateFieldsCountArgs {
  columns?: Maybe<Array<CalendarSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Calendar" */
export interface CalendarAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<CalendarMaxOrderBy>;
  min?: Maybe<CalendarMinOrderBy>;
}

/** input type for inserting array relation for remote table "Calendar" */
export interface CalendarArrRelInsertInput {
  data: Array<CalendarInsertInput>;
  on_conflict?: Maybe<CalendarOnConflict>;
}

/** Boolean expression to filter rows from the table "Calendar". All fields are combined with a logical 'AND'. */
export interface CalendarBoolExp {
  Events?: Maybe<EventsBoolExp>;
  _and?: Maybe<Array<Maybe<CalendarBoolExp>>>;
  _not?: Maybe<CalendarBoolExp>;
  _or?: Maybe<Array<Maybe<CalendarBoolExp>>>;
  canEdit?: Maybe<BooleanComparisonExp>;
  color?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  lastSynced?: Maybe<TimestamptzComparisonExp>;
  primary?: Maybe<BooleanComparisonExp>;
  provider?: Maybe<CalendarProvidersEnumComparisonExp>;
  sync?: Maybe<BooleanComparisonExp>;
  syncToken?: Maybe<StringComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updatedAt?: Maybe<TimestamptzComparisonExp>;
  userId?: Maybe<StringComparisonExp>;
  uuid?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "Calendar" */
export enum CalendarConstraint {
  /** unique or primary key constraint */
  CalendarPkey = 'Calendar_pkey',
  /** unique or primary key constraint */
  CalendarUuidKey = 'Calendar_uuid_key'
}

/** input type for inserting data into table "Calendar" */
export interface CalendarInsertInput {
  Events?: Maybe<EventsArrRelInsertInput>;
  canEdit?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['timestamptz']>;
  primary?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<CalendarProvidersEnum>;
  sync?: Maybe<Scalars['Boolean']>;
  syncToken?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface CalendarMaxFields {
  __typename?: 'Calendar_max_fields';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['timestamptz']>;
  syncToken?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "Calendar" */
export interface CalendarMaxOrderBy {
  color?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lastSynced?: Maybe<OrderBy>;
  syncToken?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface CalendarMinFields {
  __typename?: 'Calendar_min_fields';
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['timestamptz']>;
  syncToken?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "Calendar" */
export interface CalendarMinOrderBy {
  color?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lastSynced?: Maybe<OrderBy>;
  syncToken?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Calendar" */
export interface CalendarMutationResponse {
  __typename?: 'Calendar_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Calendar>;
}

/** input type for inserting object relation for remote table "Calendar" */
export interface CalendarObjRelInsertInput {
  data: CalendarInsertInput;
  on_conflict?: Maybe<CalendarOnConflict>;
}

/** on conflict condition type for table "Calendar" */
export interface CalendarOnConflict {
  constraint: CalendarConstraint;
  update_columns: Array<CalendarUpdateColumn>;
  where?: Maybe<CalendarBoolExp>;
}

/** ordering options when selecting data from "Calendar" */
export interface CalendarOrderBy {
  Events_aggregate?: Maybe<EventsAggregateOrderBy>;
  canEdit?: Maybe<OrderBy>;
  color?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lastSynced?: Maybe<OrderBy>;
  primary?: Maybe<OrderBy>;
  provider?: Maybe<OrderBy>;
  sync?: Maybe<OrderBy>;
  syncToken?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Calendar" */
export interface CalendarPkColumnsInput {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}

/** select columns of table "Calendar" */
export enum CalendarSelectColumn {
  /** column name */
  CanEdit = 'canEdit',
  /** column name */
  Color = 'color',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastSynced = 'lastSynced',
  /** column name */
  Primary = 'primary',
  /** column name */
  Provider = 'provider',
  /** column name */
  Sync = 'sync',
  /** column name */
  SyncToken = 'syncToken',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "Calendar" */
export interface CalendarSetInput {
  canEdit?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastSynced?: Maybe<Scalars['timestamptz']>;
  primary?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<CalendarProvidersEnum>;
  sync?: Maybe<Scalars['Boolean']>;
  syncToken?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** update columns of table "Calendar" */
export enum CalendarUpdateColumn {
  /** column name */
  CanEdit = 'canEdit',
  /** column name */
  Color = 'color',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastSynced = 'lastSynced',
  /** column name */
  Primary = 'primary',
  /** column name */
  Provider = 'provider',
  /** column name */
  Sync = 'sync',
  /** column name */
  SyncToken = 'syncToken',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Uuid = 'uuid'
}

/** columns and relationships of "Event_Status" */
export interface EventStatus {
  __typename?: 'Event_Status';
  description: Scalars['String'];
  value: Scalars['String'];
}

/** aggregated selection of "Event_Status" */
export interface EventStatusAggregate {
  __typename?: 'Event_Status_aggregate';
  aggregate?: Maybe<EventStatusAggregateFields>;
  nodes: Array<EventStatus>;
}

/** aggregate fields of "Event_Status" */
export interface EventStatusAggregateFields {
  __typename?: 'Event_Status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<EventStatusMaxFields>;
  min?: Maybe<EventStatusMinFields>;
}


/** aggregate fields of "Event_Status" */
export interface EventStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<EventStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Event_Status" */
export interface EventStatusAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<EventStatusMaxOrderBy>;
  min?: Maybe<EventStatusMinOrderBy>;
}

/** input type for inserting array relation for remote table "Event_Status" */
export interface EventStatusArrRelInsertInput {
  data: Array<EventStatusInsertInput>;
  on_conflict?: Maybe<EventStatusOnConflict>;
}

/** Boolean expression to filter rows from the table "Event_Status". All fields are combined with a logical 'AND'. */
export interface EventStatusBoolExp {
  _and?: Maybe<Array<Maybe<EventStatusBoolExp>>>;
  _not?: Maybe<EventStatusBoolExp>;
  _or?: Maybe<Array<Maybe<EventStatusBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  value?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "Event_Status" */
export enum EventStatusConstraint {
  /** unique or primary key constraint */
  EventStatusPkey = 'Event_Status_pkey'
}

export enum EventStatusEnum {
  /** The event is cancelled (deleted). */
  Cancelled = 'cancelled',
  /** The event is confirmed. Default. */
  Confirmed = 'confirmed',
  /** The event is tentatively confirmed. */
  Tentative = 'tentative'
}

/** expression to compare columns of type Event_Status_enum. All fields are combined with logical 'AND'. */
export interface EventStatusEnumComparisonExp {
  _eq?: Maybe<EventStatusEnum>;
  _in?: Maybe<Array<EventStatusEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<EventStatusEnum>;
  _nin?: Maybe<Array<EventStatusEnum>>;
}

/** input type for inserting data into table "Event_Status" */
export interface EventStatusInsertInput {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface EventStatusMaxFields {
  __typename?: 'Event_Status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "Event_Status" */
export interface EventStatusMaxOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface EventStatusMinFields {
  __typename?: 'Event_Status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "Event_Status" */
export interface EventStatusMinOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Event_Status" */
export interface EventStatusMutationResponse {
  __typename?: 'Event_Status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<EventStatus>;
}

/** input type for inserting object relation for remote table "Event_Status" */
export interface EventStatusObjRelInsertInput {
  data: EventStatusInsertInput;
  on_conflict?: Maybe<EventStatusOnConflict>;
}

/** on conflict condition type for table "Event_Status" */
export interface EventStatusOnConflict {
  constraint: EventStatusConstraint;
  update_columns: Array<EventStatusUpdateColumn>;
  where?: Maybe<EventStatusBoolExp>;
}

/** ordering options when selecting data from "Event_Status" */
export interface EventStatusOrderBy {
  description?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Event_Status" */
export interface EventStatusPkColumnsInput {
  value: Scalars['String'];
}

/** select columns of table "Event_Status" */
export enum EventStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Event_Status" */
export interface EventStatusSetInput {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

/** update columns of table "Event_Status" */
export enum EventStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "Events" */
export interface Events {
  __typename?: 'Events';
  /** An object relationship */
  Calendar?: Maybe<Calendar>;
  allDay: Scalars['Boolean'];
  attendees?: Maybe<Scalars['jsonb']>;
  calendarId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  creator?: Maybe<Scalars['jsonb']>;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endTime: Scalars['timestamptz'];
  iCalUID?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDetached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizer?: Maybe<Scalars['jsonb']>;
  provider: CalendarProvidersEnum;
  recurrenceRule?: Maybe<Scalars['String']>;
  recurringEventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  startTime: Scalars['timestamptz'];
  status?: Maybe<EventStatusEnum>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  uuid: Scalars['uuid'];
}


/** columns and relationships of "Events" */
export interface EventsAttendeesArgs {
  path?: Maybe<Scalars['String']>;
}


/** columns and relationships of "Events" */
export interface EventsCreatorArgs {
  path?: Maybe<Scalars['String']>;
}


/** columns and relationships of "Events" */
export interface EventsOrganizerArgs {
  path?: Maybe<Scalars['String']>;
}

/** aggregated selection of "Events" */
export interface EventsAggregate {
  __typename?: 'Events_aggregate';
  aggregate?: Maybe<EventsAggregateFields>;
  nodes: Array<Events>;
}

/** aggregate fields of "Events" */
export interface EventsAggregateFields {
  __typename?: 'Events_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<EventsMaxFields>;
  min?: Maybe<EventsMinFields>;
}


/** aggregate fields of "Events" */
export interface EventsAggregateFieldsCountArgs {
  columns?: Maybe<Array<EventsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Events" */
export interface EventsAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<EventsMaxOrderBy>;
  min?: Maybe<EventsMinOrderBy>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface EventsAppendInput {
  attendees?: Maybe<Scalars['jsonb']>;
  creator?: Maybe<Scalars['jsonb']>;
  organizer?: Maybe<Scalars['jsonb']>;
}

/** input type for inserting array relation for remote table "Events" */
export interface EventsArrRelInsertInput {
  data: Array<EventsInsertInput>;
  on_conflict?: Maybe<EventsOnConflict>;
}

/** Boolean expression to filter rows from the table "Events". All fields are combined with a logical 'AND'. */
export interface EventsBoolExp {
  Calendar?: Maybe<CalendarBoolExp>;
  _and?: Maybe<Array<Maybe<EventsBoolExp>>>;
  _not?: Maybe<EventsBoolExp>;
  _or?: Maybe<Array<Maybe<EventsBoolExp>>>;
  allDay?: Maybe<BooleanComparisonExp>;
  attendees?: Maybe<JsonbComparisonExp>;
  calendarId?: Maybe<StringComparisonExp>;
  createdAt?: Maybe<TimestamptzComparisonExp>;
  creator?: Maybe<JsonbComparisonExp>;
  deleted?: Maybe<BooleanComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  endDate?: Maybe<DateComparisonExp>;
  endTime?: Maybe<TimestamptzComparisonExp>;
  iCalUID?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  isDetached?: Maybe<BooleanComparisonExp>;
  location?: Maybe<StringComparisonExp>;
  notes?: Maybe<StringComparisonExp>;
  organizer?: Maybe<JsonbComparisonExp>;
  provider?: Maybe<CalendarProvidersEnumComparisonExp>;
  recurrenceRule?: Maybe<StringComparisonExp>;
  recurringEventId?: Maybe<StringComparisonExp>;
  startDate?: Maybe<DateComparisonExp>;
  startTime?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<EventStatusEnumComparisonExp>;
  timezone?: Maybe<StringComparisonExp>;
  title?: Maybe<StringComparisonExp>;
  updatedAt?: Maybe<TimestamptzComparisonExp>;
  url?: Maybe<StringComparisonExp>;
  userId?: Maybe<StringComparisonExp>;
  uuid?: Maybe<UuidComparisonExp>;
}

/** unique or primary key constraints on table "Events" */
export enum EventsConstraint {
  /** unique or primary key constraint */
  EventsPkey = 'Events_pkey',
  /** unique or primary key constraint */
  EventsUuidKey = 'Events_uuid_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface EventsDeleteAtPathInput {
  attendees?: Maybe<Array<Maybe<Scalars['String']>>>;
  creator?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizer?: Maybe<Array<Maybe<Scalars['String']>>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface EventsDeleteElemInput {
  attendees?: Maybe<Scalars['Int']>;
  creator?: Maybe<Scalars['Int']>;
  organizer?: Maybe<Scalars['Int']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface EventsDeleteKeyInput {
  attendees?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  organizer?: Maybe<Scalars['String']>;
}

/** input type for inserting data into table "Events" */
export interface EventsInsertInput {
  Calendar?: Maybe<CalendarObjRelInsertInput>;
  allDay?: Maybe<Scalars['Boolean']>;
  attendees?: Maybe<Scalars['jsonb']>;
  calendarId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creator?: Maybe<Scalars['jsonb']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDetached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizer?: Maybe<Scalars['jsonb']>;
  provider?: Maybe<CalendarProvidersEnum>;
  recurrenceRule?: Maybe<Scalars['String']>;
  recurringEventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<EventStatusEnum>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** aggregate max on columns */
export interface EventsMaxFields {
  __typename?: 'Events_max_fields';
  calendarId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  recurrenceRule?: Maybe<Scalars['String']>;
  recurringEventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** order by max() on columns of table "Events" */
export interface EventsMaxOrderBy {
  calendarId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
  endTime?: Maybe<OrderBy>;
  iCalUID?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  location?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  recurrenceRule?: Maybe<OrderBy>;
  recurringEventId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
  startTime?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface EventsMinFields {
  __typename?: 'Events_min_fields';
  calendarId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  recurrenceRule?: Maybe<Scalars['String']>;
  recurringEventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** order by min() on columns of table "Events" */
export interface EventsMinOrderBy {
  calendarId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
  endTime?: Maybe<OrderBy>;
  iCalUID?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  location?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  recurrenceRule?: Maybe<OrderBy>;
  recurringEventId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
  startTime?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Events" */
export interface EventsMutationResponse {
  __typename?: 'Events_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Events>;
}

/** input type for inserting object relation for remote table "Events" */
export interface EventsObjRelInsertInput {
  data: EventsInsertInput;
  on_conflict?: Maybe<EventsOnConflict>;
}

/** on conflict condition type for table "Events" */
export interface EventsOnConflict {
  constraint: EventsConstraint;
  update_columns: Array<EventsUpdateColumn>;
  where?: Maybe<EventsBoolExp>;
}

/** ordering options when selecting data from "Events" */
export interface EventsOrderBy {
  Calendar?: Maybe<CalendarOrderBy>;
  allDay?: Maybe<OrderBy>;
  attendees?: Maybe<OrderBy>;
  calendarId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  creator?: Maybe<OrderBy>;
  deleted?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
  endTime?: Maybe<OrderBy>;
  iCalUID?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isDetached?: Maybe<OrderBy>;
  location?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  organizer?: Maybe<OrderBy>;
  provider?: Maybe<OrderBy>;
  recurrenceRule?: Maybe<OrderBy>;
  recurringEventId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
  startTime?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  timezone?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Events" */
export interface EventsPkColumnsInput {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface EventsPrependInput {
  attendees?: Maybe<Scalars['jsonb']>;
  creator?: Maybe<Scalars['jsonb']>;
  organizer?: Maybe<Scalars['jsonb']>;
}

/** select columns of table "Events" */
export enum EventsSelectColumn {
  /** column name */
  AllDay = 'allDay',
  /** column name */
  Attendees = 'attendees',
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Creator = 'creator',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  ICalUid = 'iCalUID',
  /** column name */
  Id = 'id',
  /** column name */
  IsDetached = 'isDetached',
  /** column name */
  Location = 'location',
  /** column name */
  Notes = 'notes',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  Provider = 'provider',
  /** column name */
  RecurrenceRule = 'recurrenceRule',
  /** column name */
  RecurringEventId = 'recurringEventId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Status = 'status',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "Events" */
export interface EventsSetInput {
  allDay?: Maybe<Scalars['Boolean']>;
  attendees?: Maybe<Scalars['jsonb']>;
  calendarId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  creator?: Maybe<Scalars['jsonb']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDetached?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizer?: Maybe<Scalars['jsonb']>;
  provider?: Maybe<CalendarProvidersEnum>;
  recurrenceRule?: Maybe<Scalars['String']>;
  recurringEventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  startTime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<EventStatusEnum>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
}

/** update columns of table "Events" */
export enum EventsUpdateColumn {
  /** column name */
  AllDay = 'allDay',
  /** column name */
  Attendees = 'attendees',
  /** column name */
  CalendarId = 'calendarId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Creator = 'creator',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  ICalUid = 'iCalUID',
  /** column name */
  Id = 'id',
  /** column name */
  IsDetached = 'isDetached',
  /** column name */
  Location = 'location',
  /** column name */
  Notes = 'notes',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  Provider = 'provider',
  /** column name */
  RecurrenceRule = 'recurrenceRule',
  /** column name */
  RecurringEventId = 'recurringEventId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  Status = 'status',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
  /** column name */
  Uuid = 'uuid'
}

/** columns and relationships of "Group_Calendar" */
export interface GroupCalendar {
  __typename?: 'Group_Calendar';
  description: Scalars['String'];
  id: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
}

/** columns and relationships of "Group_Calendar_Calendar" */
export interface GroupCalendarCalendar {
  __typename?: 'Group_Calendar_Calendar';
  calId: Scalars['String'];
  groupCalId: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}

/** aggregated selection of "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarAggregate {
  __typename?: 'Group_Calendar_Calendar_aggregate';
  aggregate?: Maybe<GroupCalendarCalendarAggregateFields>;
  nodes: Array<GroupCalendarCalendar>;
}

/** aggregate fields of "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarAggregateFields {
  __typename?: 'Group_Calendar_Calendar_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<GroupCalendarCalendarMaxFields>;
  min?: Maybe<GroupCalendarCalendarMinFields>;
}


/** aggregate fields of "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarAggregateFieldsCountArgs {
  columns?: Maybe<Array<GroupCalendarCalendarSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupCalendarCalendarMaxOrderBy>;
  min?: Maybe<GroupCalendarCalendarMinOrderBy>;
}

/** input type for inserting array relation for remote table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarArrRelInsertInput {
  data: Array<GroupCalendarCalendarInsertInput>;
  on_conflict?: Maybe<GroupCalendarCalendarOnConflict>;
}

/** Boolean expression to filter rows from the table "Group_Calendar_Calendar". All fields are combined with a logical 'AND'. */
export interface GroupCalendarCalendarBoolExp {
  _and?: Maybe<Array<Maybe<GroupCalendarCalendarBoolExp>>>;
  _not?: Maybe<GroupCalendarCalendarBoolExp>;
  _or?: Maybe<Array<Maybe<GroupCalendarCalendarBoolExp>>>;
  calId?: Maybe<StringComparisonExp>;
  groupCalId?: Maybe<StringComparisonExp>;
  provider?: Maybe<CalendarProvidersEnumComparisonExp>;
  userId?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "Group_Calendar_Calendar" */
export enum GroupCalendarCalendarConstraint {
  /** unique or primary key constraint */
  GroupCalendarCalendarPkey = 'Group_Calendar_Calendar_pkey'
}

/** input type for inserting data into table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarInsertInput {
  calId?: Maybe<Scalars['String']>;
  groupCalId?: Maybe<Scalars['String']>;
  provider?: Maybe<CalendarProvidersEnum>;
  userId?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface GroupCalendarCalendarMaxFields {
  __typename?: 'Group_Calendar_Calendar_max_fields';
  calId?: Maybe<Scalars['String']>;
  groupCalId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarMaxOrderBy {
  calId?: Maybe<OrderBy>;
  groupCalId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface GroupCalendarCalendarMinFields {
  __typename?: 'Group_Calendar_Calendar_min_fields';
  calId?: Maybe<Scalars['String']>;
  groupCalId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarMinOrderBy {
  calId?: Maybe<OrderBy>;
  groupCalId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarMutationResponse {
  __typename?: 'Group_Calendar_Calendar_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<GroupCalendarCalendar>;
}

/** input type for inserting object relation for remote table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarObjRelInsertInput {
  data: GroupCalendarCalendarInsertInput;
  on_conflict?: Maybe<GroupCalendarCalendarOnConflict>;
}

/** on conflict condition type for table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarOnConflict {
  constraint: GroupCalendarCalendarConstraint;
  update_columns: Array<GroupCalendarCalendarUpdateColumn>;
  where?: Maybe<GroupCalendarCalendarBoolExp>;
}

/** ordering options when selecting data from "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarOrderBy {
  calId?: Maybe<OrderBy>;
  groupCalId?: Maybe<OrderBy>;
  provider?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarPkColumnsInput {
  calId: Scalars['String'];
  groupCalId: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}

/** select columns of table "Group_Calendar_Calendar" */
export enum GroupCalendarCalendarSelectColumn {
  /** column name */
  CalId = 'calId',
  /** column name */
  GroupCalId = 'groupCalId',
  /** column name */
  Provider = 'provider',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "Group_Calendar_Calendar" */
export interface GroupCalendarCalendarSetInput {
  calId?: Maybe<Scalars['String']>;
  groupCalId?: Maybe<Scalars['String']>;
  provider?: Maybe<CalendarProvidersEnum>;
  userId?: Maybe<Scalars['String']>;
}

/** update columns of table "Group_Calendar_Calendar" */
export enum GroupCalendarCalendarUpdateColumn {
  /** column name */
  CalId = 'calId',
  /** column name */
  GroupCalId = 'groupCalId',
  /** column name */
  Provider = 'provider',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "Group_Calendar_User" */
export interface GroupCalendarUser {
  __typename?: 'Group_Calendar_User';
  /** An object relationship */
  Asuvi_Role: AsuviRoles;
  /** An object relationship */
  Group_Calendar: GroupCalendar;
  /** An object relationship */
  User: User;
  favorite: Scalars['Boolean'];
  groupCalId: Scalars['String'];
  role: AsuviRolesEnum;
  userId: Scalars['String'];
}

/** aggregated selection of "Group_Calendar_User" */
export interface GroupCalendarUserAggregate {
  __typename?: 'Group_Calendar_User_aggregate';
  aggregate?: Maybe<GroupCalendarUserAggregateFields>;
  nodes: Array<GroupCalendarUser>;
}

/** aggregate fields of "Group_Calendar_User" */
export interface GroupCalendarUserAggregateFields {
  __typename?: 'Group_Calendar_User_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<GroupCalendarUserMaxFields>;
  min?: Maybe<GroupCalendarUserMinFields>;
}


/** aggregate fields of "Group_Calendar_User" */
export interface GroupCalendarUserAggregateFieldsCountArgs {
  columns?: Maybe<Array<GroupCalendarUserSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Group_Calendar_User" */
export interface GroupCalendarUserAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupCalendarUserMaxOrderBy>;
  min?: Maybe<GroupCalendarUserMinOrderBy>;
}

/** input type for inserting array relation for remote table "Group_Calendar_User" */
export interface GroupCalendarUserArrRelInsertInput {
  data: Array<GroupCalendarUserInsertInput>;
  on_conflict?: Maybe<GroupCalendarUserOnConflict>;
}

/** Boolean expression to filter rows from the table "Group_Calendar_User". All fields are combined with a logical 'AND'. */
export interface GroupCalendarUserBoolExp {
  Asuvi_Role?: Maybe<AsuviRolesBoolExp>;
  Group_Calendar?: Maybe<GroupCalendarBoolExp>;
  User?: Maybe<UserBoolExp>;
  _and?: Maybe<Array<Maybe<GroupCalendarUserBoolExp>>>;
  _not?: Maybe<GroupCalendarUserBoolExp>;
  _or?: Maybe<Array<Maybe<GroupCalendarUserBoolExp>>>;
  favorite?: Maybe<BooleanComparisonExp>;
  groupCalId?: Maybe<StringComparisonExp>;
  role?: Maybe<AsuviRolesEnumComparisonExp>;
  userId?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "Group_Calendar_User" */
export enum GroupCalendarUserConstraint {
  /** unique or primary key constraint */
  GroupCalendarUserPkey = 'Group_Calendar_User_pkey'
}

/** input type for inserting data into table "Group_Calendar_User" */
export interface GroupCalendarUserInsertInput {
  Asuvi_Role?: Maybe<AsuviRolesObjRelInsertInput>;
  Group_Calendar?: Maybe<GroupCalendarObjRelInsertInput>;
  User?: Maybe<UserObjRelInsertInput>;
  favorite?: Maybe<Scalars['Boolean']>;
  groupCalId?: Maybe<Scalars['String']>;
  role?: Maybe<AsuviRolesEnum>;
  userId?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface GroupCalendarUserMaxFields {
  __typename?: 'Group_Calendar_User_max_fields';
  groupCalId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "Group_Calendar_User" */
export interface GroupCalendarUserMaxOrderBy {
  groupCalId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface GroupCalendarUserMinFields {
  __typename?: 'Group_Calendar_User_min_fields';
  groupCalId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "Group_Calendar_User" */
export interface GroupCalendarUserMinOrderBy {
  groupCalId?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Group_Calendar_User" */
export interface GroupCalendarUserMutationResponse {
  __typename?: 'Group_Calendar_User_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<GroupCalendarUser>;
}

/** input type for inserting object relation for remote table "Group_Calendar_User" */
export interface GroupCalendarUserObjRelInsertInput {
  data: GroupCalendarUserInsertInput;
  on_conflict?: Maybe<GroupCalendarUserOnConflict>;
}

/** on conflict condition type for table "Group_Calendar_User" */
export interface GroupCalendarUserOnConflict {
  constraint: GroupCalendarUserConstraint;
  update_columns: Array<GroupCalendarUserUpdateColumn>;
  where?: Maybe<GroupCalendarUserBoolExp>;
}

/** ordering options when selecting data from "Group_Calendar_User" */
export interface GroupCalendarUserOrderBy {
  Asuvi_Role?: Maybe<AsuviRolesOrderBy>;
  Group_Calendar?: Maybe<GroupCalendarOrderBy>;
  User?: Maybe<UserOrderBy>;
  favorite?: Maybe<OrderBy>;
  groupCalId?: Maybe<OrderBy>;
  role?: Maybe<OrderBy>;
  userId?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Group_Calendar_User" */
export interface GroupCalendarUserPkColumnsInput {
  groupCalId: Scalars['String'];
  userId: Scalars['String'];
}

/** select columns of table "Group_Calendar_User" */
export enum GroupCalendarUserSelectColumn {
  /** column name */
  Favorite = 'favorite',
  /** column name */
  GroupCalId = 'groupCalId',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "Group_Calendar_User" */
export interface GroupCalendarUserSetInput {
  favorite?: Maybe<Scalars['Boolean']>;
  groupCalId?: Maybe<Scalars['String']>;
  role?: Maybe<AsuviRolesEnum>;
  userId?: Maybe<Scalars['String']>;
}

/** update columns of table "Group_Calendar_User" */
export enum GroupCalendarUserUpdateColumn {
  /** column name */
  Favorite = 'favorite',
  /** column name */
  GroupCalId = 'groupCalId',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** aggregated selection of "Group_Calendar" */
export interface GroupCalendarAggregate {
  __typename?: 'Group_Calendar_aggregate';
  aggregate?: Maybe<GroupCalendarAggregateFields>;
  nodes: Array<GroupCalendar>;
}

/** aggregate fields of "Group_Calendar" */
export interface GroupCalendarAggregateFields {
  __typename?: 'Group_Calendar_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<GroupCalendarMaxFields>;
  min?: Maybe<GroupCalendarMinFields>;
}


/** aggregate fields of "Group_Calendar" */
export interface GroupCalendarAggregateFieldsCountArgs {
  columns?: Maybe<Array<GroupCalendarSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "Group_Calendar" */
export interface GroupCalendarAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupCalendarMaxOrderBy>;
  min?: Maybe<GroupCalendarMinOrderBy>;
}

/** input type for inserting array relation for remote table "Group_Calendar" */
export interface GroupCalendarArrRelInsertInput {
  data: Array<GroupCalendarInsertInput>;
  on_conflict?: Maybe<GroupCalendarOnConflict>;
}

/** Boolean expression to filter rows from the table "Group_Calendar". All fields are combined with a logical 'AND'. */
export interface GroupCalendarBoolExp {
  _and?: Maybe<Array<Maybe<GroupCalendarBoolExp>>>;
  _not?: Maybe<GroupCalendarBoolExp>;
  _or?: Maybe<Array<Maybe<GroupCalendarBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<StringComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
  title?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "Group_Calendar" */
export enum GroupCalendarConstraint {
  /** unique or primary key constraint */
  GroupCalendarPkey = 'Group_Calendar_pkey',
  /** unique or primary key constraint */
  GroupCalendarSlugKey = 'Group_Calendar_slug_key'
}

/** input type for inserting data into table "Group_Calendar" */
export interface GroupCalendarInsertInput {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface GroupCalendarMaxFields {
  __typename?: 'Group_Calendar_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "Group_Calendar" */
export interface GroupCalendarMaxOrderBy {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface GroupCalendarMinFields {
  __typename?: 'Group_Calendar_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "Group_Calendar" */
export interface GroupCalendarMinOrderBy {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
}

/** response of any mutation on the table "Group_Calendar" */
export interface GroupCalendarMutationResponse {
  __typename?: 'Group_Calendar_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<GroupCalendar>;
}

/** input type for inserting object relation for remote table "Group_Calendar" */
export interface GroupCalendarObjRelInsertInput {
  data: GroupCalendarInsertInput;
  on_conflict?: Maybe<GroupCalendarOnConflict>;
}

/** on conflict condition type for table "Group_Calendar" */
export interface GroupCalendarOnConflict {
  constraint: GroupCalendarConstraint;
  update_columns: Array<GroupCalendarUpdateColumn>;
  where?: Maybe<GroupCalendarBoolExp>;
}

/** ordering options when selecting data from "Group_Calendar" */
export interface GroupCalendarOrderBy {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
}

/** primary key columns input for table: "Group_Calendar" */
export interface GroupCalendarPkColumnsInput {
  id: Scalars['String'];
}

/** select columns of table "Group_Calendar" */
export enum GroupCalendarSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "Group_Calendar" */
export interface GroupCalendarSetInput {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

/** update columns of table "Group_Calendar" */
export enum GroupCalendarUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title'
}

export interface Input {
  calId: Scalars['String'];
  provider: Provider;
  sync: Scalars['Boolean'];
}

export interface Output {
  __typename?: 'Output';
  calendarId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  sync?: Maybe<Scalars['Boolean']>;
}

export enum Provider {
  Asvi = 'ASVI',
  Goog = 'GOOG',
  Msft = 'MSFT'
}

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
}

/** columns and relationships of "User" */
export interface User {
  __typename?: 'User';
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  photoURL?: Maybe<Scalars['String']>;
  /** Same as Firebase UID */
  uid: Scalars['String'];
}

/** aggregated selection of "User" */
export interface UserAggregate {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
}

/** aggregate fields of "User" */
export interface UserAggregateFields {
  __typename?: 'User_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
}


/** aggregate fields of "User" */
export interface UserAggregateFieldsCountArgs {
  columns?: Maybe<Array<UserSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
}

/** order by aggregate values of table "User" */
export interface UserAggregateOrderBy {
  count?: Maybe<OrderBy>;
  max?: Maybe<UserMaxOrderBy>;
  min?: Maybe<UserMinOrderBy>;
}

/** input type for inserting array relation for remote table "User" */
export interface UserArrRelInsertInput {
  data: Array<UserInsertInput>;
  on_conflict?: Maybe<UserOnConflict>;
}

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export interface UserBoolExp {
  _and?: Maybe<Array<Maybe<UserBoolExp>>>;
  _not?: Maybe<UserBoolExp>;
  _or?: Maybe<Array<Maybe<UserBoolExp>>>;
  displayName?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  photoURL?: Maybe<StringComparisonExp>;
  uid?: Maybe<StringComparisonExp>;
}

/** unique or primary key constraints on table "User" */
export enum UserConstraint {
  /** unique or primary key constraint */
  UserEmailKey = 'User_email_key',
  /** unique or primary key constraint */
  UserPkey = 'User_pkey',
  /** unique or primary key constraint */
  UserUidKey = 'User_uid_key'
}

/** input type for inserting data into table "User" */
export interface UserInsertInput {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
}

/** aggregate max on columns */
export interface UserMaxFields {
  __typename?: 'User_max_fields';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
}

/** order by max() on columns of table "User" */
export interface UserMaxOrderBy {
  displayName?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  photoURL?: Maybe<OrderBy>;
  uid?: Maybe<OrderBy>;
}

/** aggregate min on columns */
export interface UserMinFields {
  __typename?: 'User_min_fields';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
}

/** order by min() on columns of table "User" */
export interface UserMinOrderBy {
  displayName?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  photoURL?: Maybe<OrderBy>;
  uid?: Maybe<OrderBy>;
}

/** response of any mutation on the table "User" */
export interface UserMutationResponse {
  __typename?: 'User_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
}

/** input type for inserting object relation for remote table "User" */
export interface UserObjRelInsertInput {
  data: UserInsertInput;
  on_conflict?: Maybe<UserOnConflict>;
}

/** on conflict condition type for table "User" */
export interface UserOnConflict {
  constraint: UserConstraint;
  update_columns: Array<UserUpdateColumn>;
  where?: Maybe<UserBoolExp>;
}

/** ordering options when selecting data from "User" */
export interface UserOrderBy {
  displayName?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  photoURL?: Maybe<OrderBy>;
  uid?: Maybe<OrderBy>;
}

/** primary key columns input for table: "User" */
export interface UserPkColumnsInput {
  /** Same as Firebase UID */
  uid: Scalars['String'];
}

/** select columns of table "User" */
export enum UserSelectColumn {
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  PhotoUrl = 'photoURL',
  /** column name */
  Uid = 'uid'
}

/** input type for updating data in table "User" */
export interface UserSetInput {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
}

/** update columns of table "User" */
export enum UserUpdateColumn {
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  PhotoUrl = 'photoURL',
  /** column name */
  Uid = 'uid'
}


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export interface DateComparisonExp {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
}


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export interface JsonComparisonExp {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
}


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export interface JsonbComparisonExp {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
}

/** mutation root */
export interface MutationRoot {
  __typename?: 'mutation_root';
  /** delete data from the table: "Asuvi_Roles" */
  delete_Asuvi_Roles?: Maybe<AsuviRolesMutationResponse>;
  /** delete single row from the table: "Asuvi_Roles" */
  delete_Asuvi_Roles_by_pk?: Maybe<AsuviRoles>;
  /** delete data from the table: "Calendar" */
  delete_Calendar?: Maybe<CalendarMutationResponse>;
  /** delete data from the table: "Calendar_Providers" */
  delete_Calendar_Providers?: Maybe<CalendarProvidersMutationResponse>;
  /** delete single row from the table: "Calendar_Providers" */
  delete_Calendar_Providers_by_pk?: Maybe<CalendarProviders>;
  /** delete single row from the table: "Calendar" */
  delete_Calendar_by_pk?: Maybe<Calendar>;
  /** delete data from the table: "Event_Status" */
  delete_Event_Status?: Maybe<EventStatusMutationResponse>;
  /** delete single row from the table: "Event_Status" */
  delete_Event_Status_by_pk?: Maybe<EventStatus>;
  /** delete data from the table: "Events" */
  delete_Events?: Maybe<EventsMutationResponse>;
  /** delete single row from the table: "Events" */
  delete_Events_by_pk?: Maybe<Events>;
  /** delete data from the table: "Group_Calendar" */
  delete_Group_Calendar?: Maybe<GroupCalendarMutationResponse>;
  /** delete data from the table: "Group_Calendar_Calendar" */
  delete_Group_Calendar_Calendar?: Maybe<GroupCalendarCalendarMutationResponse>;
  /** delete single row from the table: "Group_Calendar_Calendar" */
  delete_Group_Calendar_Calendar_by_pk?: Maybe<GroupCalendarCalendar>;
  /** delete data from the table: "Group_Calendar_User" */
  delete_Group_Calendar_User?: Maybe<GroupCalendarUserMutationResponse>;
  /** delete single row from the table: "Group_Calendar_User" */
  delete_Group_Calendar_User_by_pk?: Maybe<GroupCalendarUser>;
  /** delete single row from the table: "Group_Calendar" */
  delete_Group_Calendar_by_pk?: Maybe<GroupCalendar>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** insert data into the table: "Asuvi_Roles" */
  insert_Asuvi_Roles?: Maybe<AsuviRolesMutationResponse>;
  /** insert a single row into the table: "Asuvi_Roles" */
  insert_Asuvi_Roles_one?: Maybe<AsuviRoles>;
  /** insert data into the table: "Calendar" */
  insert_Calendar?: Maybe<CalendarMutationResponse>;
  /** insert data into the table: "Calendar_Providers" */
  insert_Calendar_Providers?: Maybe<CalendarProvidersMutationResponse>;
  /** insert a single row into the table: "Calendar_Providers" */
  insert_Calendar_Providers_one?: Maybe<CalendarProviders>;
  /** insert a single row into the table: "Calendar" */
  insert_Calendar_one?: Maybe<Calendar>;
  /** insert data into the table: "Event_Status" */
  insert_Event_Status?: Maybe<EventStatusMutationResponse>;
  /** insert a single row into the table: "Event_Status" */
  insert_Event_Status_one?: Maybe<EventStatus>;
  /** insert data into the table: "Events" */
  insert_Events?: Maybe<EventsMutationResponse>;
  /** insert a single row into the table: "Events" */
  insert_Events_one?: Maybe<Events>;
  /** insert data into the table: "Group_Calendar" */
  insert_Group_Calendar?: Maybe<GroupCalendarMutationResponse>;
  /** insert data into the table: "Group_Calendar_Calendar" */
  insert_Group_Calendar_Calendar?: Maybe<GroupCalendarCalendarMutationResponse>;
  /** insert a single row into the table: "Group_Calendar_Calendar" */
  insert_Group_Calendar_Calendar_one?: Maybe<GroupCalendarCalendar>;
  /** insert data into the table: "Group_Calendar_User" */
  insert_Group_Calendar_User?: Maybe<GroupCalendarUserMutationResponse>;
  /** insert a single row into the table: "Group_Calendar_User" */
  insert_Group_Calendar_User_one?: Maybe<GroupCalendarUser>;
  /** insert a single row into the table: "Group_Calendar" */
  insert_Group_Calendar_one?: Maybe<GroupCalendar>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<UserMutationResponse>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** perform the action: "syncExternalCalendar" */
  syncExternalCalendar?: Maybe<Output>;
  /** update data of the table: "Asuvi_Roles" */
  update_Asuvi_Roles?: Maybe<AsuviRolesMutationResponse>;
  /** update single row of the table: "Asuvi_Roles" */
  update_Asuvi_Roles_by_pk?: Maybe<AsuviRoles>;
  /** update data of the table: "Calendar" */
  update_Calendar?: Maybe<CalendarMutationResponse>;
  /** update data of the table: "Calendar_Providers" */
  update_Calendar_Providers?: Maybe<CalendarProvidersMutationResponse>;
  /** update single row of the table: "Calendar_Providers" */
  update_Calendar_Providers_by_pk?: Maybe<CalendarProviders>;
  /** update single row of the table: "Calendar" */
  update_Calendar_by_pk?: Maybe<Calendar>;
  /** update data of the table: "Event_Status" */
  update_Event_Status?: Maybe<EventStatusMutationResponse>;
  /** update single row of the table: "Event_Status" */
  update_Event_Status_by_pk?: Maybe<EventStatus>;
  /** update data of the table: "Events" */
  update_Events?: Maybe<EventsMutationResponse>;
  /** update single row of the table: "Events" */
  update_Events_by_pk?: Maybe<Events>;
  /** update data of the table: "Group_Calendar" */
  update_Group_Calendar?: Maybe<GroupCalendarMutationResponse>;
  /** update data of the table: "Group_Calendar_Calendar" */
  update_Group_Calendar_Calendar?: Maybe<GroupCalendarCalendarMutationResponse>;
  /** update single row of the table: "Group_Calendar_Calendar" */
  update_Group_Calendar_Calendar_by_pk?: Maybe<GroupCalendarCalendar>;
  /** update data of the table: "Group_Calendar_User" */
  update_Group_Calendar_User?: Maybe<GroupCalendarUserMutationResponse>;
  /** update single row of the table: "Group_Calendar_User" */
  update_Group_Calendar_User_by_pk?: Maybe<GroupCalendarUser>;
  /** update single row of the table: "Group_Calendar" */
  update_Group_Calendar_by_pk?: Maybe<GroupCalendar>;
  /** update data of the table: "User" */
  update_User?: Maybe<UserMutationResponse>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
}


/** mutation root */
export interface MutationRootDeleteAsuviRolesArgs {
  where: AsuviRolesBoolExp;
}


/** mutation root */
export interface MutationRootDeleteAsuviRolesByPkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteCalendarArgs {
  where: CalendarBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCalendarProvidersArgs {
  where: CalendarProvidersBoolExp;
}


/** mutation root */
export interface MutationRootDeleteCalendarProvidersByPkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteCalendarByPkArgs {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteEventStatusArgs {
  where: EventStatusBoolExp;
}


/** mutation root */
export interface MutationRootDeleteEventStatusByPkArgs {
  value: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteEventsArgs {
  where: EventsBoolExp;
}


/** mutation root */
export interface MutationRootDeleteEventsByPkArgs {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteGroupCalendarArgs {
  where: GroupCalendarBoolExp;
}


/** mutation root */
export interface MutationRootDeleteGroupCalendarCalendarArgs {
  where: GroupCalendarCalendarBoolExp;
}


/** mutation root */
export interface MutationRootDeleteGroupCalendarCalendarByPkArgs {
  calId: Scalars['String'];
  groupCalId: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteGroupCalendarUserArgs {
  where: GroupCalendarUserBoolExp;
}


/** mutation root */
export interface MutationRootDeleteGroupCalendarUserByPkArgs {
  groupCalId: Scalars['String'];
  userId: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteGroupCalendarByPkArgs {
  id: Scalars['String'];
}


/** mutation root */
export interface MutationRootDeleteUserArgs {
  where: UserBoolExp;
}


/** mutation root */
export interface MutationRootDeleteUserByPkArgs {
  uid: Scalars['String'];
}


/** mutation root */
export interface MutationRootInsertAsuviRolesArgs {
  objects: Array<AsuviRolesInsertInput>;
  on_conflict?: Maybe<AsuviRolesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertAsuviRolesOneArgs {
  object: AsuviRolesInsertInput;
  on_conflict?: Maybe<AsuviRolesOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCalendarArgs {
  objects: Array<CalendarInsertInput>;
  on_conflict?: Maybe<CalendarOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCalendarProvidersArgs {
  objects: Array<CalendarProvidersInsertInput>;
  on_conflict?: Maybe<CalendarProvidersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCalendarProvidersOneArgs {
  object: CalendarProvidersInsertInput;
  on_conflict?: Maybe<CalendarProvidersOnConflict>;
}


/** mutation root */
export interface MutationRootInsertCalendarOneArgs {
  object: CalendarInsertInput;
  on_conflict?: Maybe<CalendarOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventStatusArgs {
  objects: Array<EventStatusInsertInput>;
  on_conflict?: Maybe<EventStatusOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventStatusOneArgs {
  object: EventStatusInsertInput;
  on_conflict?: Maybe<EventStatusOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventsArgs {
  objects: Array<EventsInsertInput>;
  on_conflict?: Maybe<EventsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertEventsOneArgs {
  object: EventsInsertInput;
  on_conflict?: Maybe<EventsOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGroupCalendarArgs {
  objects: Array<GroupCalendarInsertInput>;
  on_conflict?: Maybe<GroupCalendarOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGroupCalendarCalendarArgs {
  objects: Array<GroupCalendarCalendarInsertInput>;
  on_conflict?: Maybe<GroupCalendarCalendarOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGroupCalendarCalendarOneArgs {
  object: GroupCalendarCalendarInsertInput;
  on_conflict?: Maybe<GroupCalendarCalendarOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGroupCalendarUserArgs {
  objects: Array<GroupCalendarUserInsertInput>;
  on_conflict?: Maybe<GroupCalendarUserOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGroupCalendarUserOneArgs {
  object: GroupCalendarUserInsertInput;
  on_conflict?: Maybe<GroupCalendarUserOnConflict>;
}


/** mutation root */
export interface MutationRootInsertGroupCalendarOneArgs {
  object: GroupCalendarInsertInput;
  on_conflict?: Maybe<GroupCalendarOnConflict>;
}


/** mutation root */
export interface MutationRootInsertUserArgs {
  objects: Array<UserInsertInput>;
  on_conflict?: Maybe<UserOnConflict>;
}


/** mutation root */
export interface MutationRootInsertUserOneArgs {
  object: UserInsertInput;
  on_conflict?: Maybe<UserOnConflict>;
}


/** mutation root */
export interface MutationRootSyncExternalCalendarArgs {
  calId: Scalars['String'];
  provider: Provider;
  sync: Scalars['Boolean'];
}


/** mutation root */
export interface MutationRootUpdateAsuviRolesArgs {
  _set?: Maybe<AsuviRolesSetInput>;
  where: AsuviRolesBoolExp;
}


/** mutation root */
export interface MutationRootUpdateAsuviRolesByPkArgs {
  _set?: Maybe<AsuviRolesSetInput>;
  pk_columns: AsuviRolesPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCalendarArgs {
  _set?: Maybe<CalendarSetInput>;
  where: CalendarBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCalendarProvidersArgs {
  _set?: Maybe<CalendarProvidersSetInput>;
  where: CalendarProvidersBoolExp;
}


/** mutation root */
export interface MutationRootUpdateCalendarProvidersByPkArgs {
  _set?: Maybe<CalendarProvidersSetInput>;
  pk_columns: CalendarProvidersPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateCalendarByPkArgs {
  _set?: Maybe<CalendarSetInput>;
  pk_columns: CalendarPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateEventStatusArgs {
  _set?: Maybe<EventStatusSetInput>;
  where: EventStatusBoolExp;
}


/** mutation root */
export interface MutationRootUpdateEventStatusByPkArgs {
  _set?: Maybe<EventStatusSetInput>;
  pk_columns: EventStatusPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateEventsArgs {
  _append?: Maybe<EventsAppendInput>;
  _delete_at_path?: Maybe<EventsDeleteAtPathInput>;
  _delete_elem?: Maybe<EventsDeleteElemInput>;
  _delete_key?: Maybe<EventsDeleteKeyInput>;
  _prepend?: Maybe<EventsPrependInput>;
  _set?: Maybe<EventsSetInput>;
  where: EventsBoolExp;
}


/** mutation root */
export interface MutationRootUpdateEventsByPkArgs {
  _append?: Maybe<EventsAppendInput>;
  _delete_at_path?: Maybe<EventsDeleteAtPathInput>;
  _delete_elem?: Maybe<EventsDeleteElemInput>;
  _delete_key?: Maybe<EventsDeleteKeyInput>;
  _prepend?: Maybe<EventsPrependInput>;
  _set?: Maybe<EventsSetInput>;
  pk_columns: EventsPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateGroupCalendarArgs {
  _set?: Maybe<GroupCalendarSetInput>;
  where: GroupCalendarBoolExp;
}


/** mutation root */
export interface MutationRootUpdateGroupCalendarCalendarArgs {
  _set?: Maybe<GroupCalendarCalendarSetInput>;
  where: GroupCalendarCalendarBoolExp;
}


/** mutation root */
export interface MutationRootUpdateGroupCalendarCalendarByPkArgs {
  _set?: Maybe<GroupCalendarCalendarSetInput>;
  pk_columns: GroupCalendarCalendarPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateGroupCalendarUserArgs {
  _set?: Maybe<GroupCalendarUserSetInput>;
  where: GroupCalendarUserBoolExp;
}


/** mutation root */
export interface MutationRootUpdateGroupCalendarUserByPkArgs {
  _set?: Maybe<GroupCalendarUserSetInput>;
  pk_columns: GroupCalendarUserPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateGroupCalendarByPkArgs {
  _set?: Maybe<GroupCalendarSetInput>;
  pk_columns: GroupCalendarPkColumnsInput;
}


/** mutation root */
export interface MutationRootUpdateUserArgs {
  _set?: Maybe<UserSetInput>;
  where: UserBoolExp;
}


/** mutation root */
export interface MutationRootUpdateUserByPkArgs {
  _set?: Maybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
}

/** column ordering options */
export enum OrderBy {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export interface QueryRoot {
  __typename?: 'query_root';
  /** fetch data from the table: "Asuvi_Roles" */
  Asuvi_Roles: Array<AsuviRoles>;
  /** fetch aggregated fields from the table: "Asuvi_Roles" */
  Asuvi_Roles_aggregate: AsuviRolesAggregate;
  /** fetch data from the table: "Asuvi_Roles" using primary key columns */
  Asuvi_Roles_by_pk?: Maybe<AsuviRoles>;
  /** fetch data from the table: "Calendar" */
  Calendar: Array<Calendar>;
  /** fetch data from the table: "Calendar_Providers" */
  Calendar_Providers: Array<CalendarProviders>;
  /** fetch aggregated fields from the table: "Calendar_Providers" */
  Calendar_Providers_aggregate: CalendarProvidersAggregate;
  /** fetch data from the table: "Calendar_Providers" using primary key columns */
  Calendar_Providers_by_pk?: Maybe<CalendarProviders>;
  /** fetch aggregated fields from the table: "Calendar" */
  Calendar_aggregate: CalendarAggregate;
  /** fetch data from the table: "Calendar" using primary key columns */
  Calendar_by_pk?: Maybe<Calendar>;
  /** fetch data from the table: "Event_Status" */
  Event_Status: Array<EventStatus>;
  /** fetch aggregated fields from the table: "Event_Status" */
  Event_Status_aggregate: EventStatusAggregate;
  /** fetch data from the table: "Event_Status" using primary key columns */
  Event_Status_by_pk?: Maybe<EventStatus>;
  /** fetch data from the table: "Events" */
  Events: Array<Events>;
  /** fetch aggregated fields from the table: "Events" */
  Events_aggregate: EventsAggregate;
  /** fetch data from the table: "Events" using primary key columns */
  Events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "Group_Calendar" */
  Group_Calendar: Array<GroupCalendar>;
  /** fetch data from the table: "Group_Calendar_Calendar" */
  Group_Calendar_Calendar: Array<GroupCalendarCalendar>;
  /** fetch aggregated fields from the table: "Group_Calendar_Calendar" */
  Group_Calendar_Calendar_aggregate: GroupCalendarCalendarAggregate;
  /** fetch data from the table: "Group_Calendar_Calendar" using primary key columns */
  Group_Calendar_Calendar_by_pk?: Maybe<GroupCalendarCalendar>;
  /** fetch data from the table: "Group_Calendar_User" */
  Group_Calendar_User: Array<GroupCalendarUser>;
  /** fetch aggregated fields from the table: "Group_Calendar_User" */
  Group_Calendar_User_aggregate: GroupCalendarUserAggregate;
  /** fetch data from the table: "Group_Calendar_User" using primary key columns */
  Group_Calendar_User_by_pk?: Maybe<GroupCalendarUser>;
  /** fetch aggregated fields from the table: "Group_Calendar" */
  Group_Calendar_aggregate: GroupCalendarAggregate;
  /** fetch data from the table: "Group_Calendar" using primary key columns */
  Group_Calendar_by_pk?: Maybe<GroupCalendar>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: UserAggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
}


/** query root */
export interface QueryRootAsuviRolesArgs {
  distinct_on?: Maybe<Array<AsuviRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AsuviRolesOrderBy>>;
  where?: Maybe<AsuviRolesBoolExp>;
}


/** query root */
export interface QueryRootAsuviRolesAggregateArgs {
  distinct_on?: Maybe<Array<AsuviRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AsuviRolesOrderBy>>;
  where?: Maybe<AsuviRolesBoolExp>;
}


/** query root */
export interface QueryRootAsuviRolesByPkArgs {
  value: Scalars['String'];
}


/** query root */
export interface QueryRootCalendarArgs {
  distinct_on?: Maybe<Array<CalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarOrderBy>>;
  where?: Maybe<CalendarBoolExp>;
}


/** query root */
export interface QueryRootCalendarProvidersArgs {
  distinct_on?: Maybe<Array<CalendarProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarProvidersOrderBy>>;
  where?: Maybe<CalendarProvidersBoolExp>;
}


/** query root */
export interface QueryRootCalendarProvidersAggregateArgs {
  distinct_on?: Maybe<Array<CalendarProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarProvidersOrderBy>>;
  where?: Maybe<CalendarProvidersBoolExp>;
}


/** query root */
export interface QueryRootCalendarProvidersByPkArgs {
  value: Scalars['String'];
}


/** query root */
export interface QueryRootCalendarAggregateArgs {
  distinct_on?: Maybe<Array<CalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarOrderBy>>;
  where?: Maybe<CalendarBoolExp>;
}


/** query root */
export interface QueryRootCalendarByPkArgs {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** query root */
export interface QueryRootEventStatusArgs {
  distinct_on?: Maybe<Array<EventStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventStatusOrderBy>>;
  where?: Maybe<EventStatusBoolExp>;
}


/** query root */
export interface QueryRootEventStatusAggregateArgs {
  distinct_on?: Maybe<Array<EventStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventStatusOrderBy>>;
  where?: Maybe<EventStatusBoolExp>;
}


/** query root */
export interface QueryRootEventStatusByPkArgs {
  value: Scalars['String'];
}


/** query root */
export interface QueryRootEventsArgs {
  distinct_on?: Maybe<Array<EventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventsOrderBy>>;
  where?: Maybe<EventsBoolExp>;
}


/** query root */
export interface QueryRootEventsAggregateArgs {
  distinct_on?: Maybe<Array<EventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventsOrderBy>>;
  where?: Maybe<EventsBoolExp>;
}


/** query root */
export interface QueryRootEventsByPkArgs {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** query root */
export interface QueryRootGroupCalendarArgs {
  distinct_on?: Maybe<Array<GroupCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarOrderBy>>;
  where?: Maybe<GroupCalendarBoolExp>;
}


/** query root */
export interface QueryRootGroupCalendarCalendarArgs {
  distinct_on?: Maybe<Array<GroupCalendarCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarCalendarOrderBy>>;
  where?: Maybe<GroupCalendarCalendarBoolExp>;
}


/** query root */
export interface QueryRootGroupCalendarCalendarAggregateArgs {
  distinct_on?: Maybe<Array<GroupCalendarCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarCalendarOrderBy>>;
  where?: Maybe<GroupCalendarCalendarBoolExp>;
}


/** query root */
export interface QueryRootGroupCalendarCalendarByPkArgs {
  calId: Scalars['String'];
  groupCalId: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** query root */
export interface QueryRootGroupCalendarUserArgs {
  distinct_on?: Maybe<Array<GroupCalendarUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarUserOrderBy>>;
  where?: Maybe<GroupCalendarUserBoolExp>;
}


/** query root */
export interface QueryRootGroupCalendarUserAggregateArgs {
  distinct_on?: Maybe<Array<GroupCalendarUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarUserOrderBy>>;
  where?: Maybe<GroupCalendarUserBoolExp>;
}


/** query root */
export interface QueryRootGroupCalendarUserByPkArgs {
  groupCalId: Scalars['String'];
  userId: Scalars['String'];
}


/** query root */
export interface QueryRootGroupCalendarAggregateArgs {
  distinct_on?: Maybe<Array<GroupCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarOrderBy>>;
  where?: Maybe<GroupCalendarBoolExp>;
}


/** query root */
export interface QueryRootGroupCalendarByPkArgs {
  id: Scalars['String'];
}


/** query root */
export interface QueryRootUserArgs {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
}


/** query root */
export interface QueryRootUserAggregateArgs {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
}


/** query root */
export interface QueryRootUserByPkArgs {
  uid: Scalars['String'];
}

/** subscription root */
export interface SubscriptionRoot {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Asuvi_Roles" */
  Asuvi_Roles: Array<AsuviRoles>;
  /** fetch aggregated fields from the table: "Asuvi_Roles" */
  Asuvi_Roles_aggregate: AsuviRolesAggregate;
  /** fetch data from the table: "Asuvi_Roles" using primary key columns */
  Asuvi_Roles_by_pk?: Maybe<AsuviRoles>;
  /** fetch data from the table: "Calendar" */
  Calendar: Array<Calendar>;
  /** fetch data from the table: "Calendar_Providers" */
  Calendar_Providers: Array<CalendarProviders>;
  /** fetch aggregated fields from the table: "Calendar_Providers" */
  Calendar_Providers_aggregate: CalendarProvidersAggregate;
  /** fetch data from the table: "Calendar_Providers" using primary key columns */
  Calendar_Providers_by_pk?: Maybe<CalendarProviders>;
  /** fetch aggregated fields from the table: "Calendar" */
  Calendar_aggregate: CalendarAggregate;
  /** fetch data from the table: "Calendar" using primary key columns */
  Calendar_by_pk?: Maybe<Calendar>;
  /** fetch data from the table: "Event_Status" */
  Event_Status: Array<EventStatus>;
  /** fetch aggregated fields from the table: "Event_Status" */
  Event_Status_aggregate: EventStatusAggregate;
  /** fetch data from the table: "Event_Status" using primary key columns */
  Event_Status_by_pk?: Maybe<EventStatus>;
  /** fetch data from the table: "Events" */
  Events: Array<Events>;
  /** fetch aggregated fields from the table: "Events" */
  Events_aggregate: EventsAggregate;
  /** fetch data from the table: "Events" using primary key columns */
  Events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "Group_Calendar" */
  Group_Calendar: Array<GroupCalendar>;
  /** fetch data from the table: "Group_Calendar_Calendar" */
  Group_Calendar_Calendar: Array<GroupCalendarCalendar>;
  /** fetch aggregated fields from the table: "Group_Calendar_Calendar" */
  Group_Calendar_Calendar_aggregate: GroupCalendarCalendarAggregate;
  /** fetch data from the table: "Group_Calendar_Calendar" using primary key columns */
  Group_Calendar_Calendar_by_pk?: Maybe<GroupCalendarCalendar>;
  /** fetch data from the table: "Group_Calendar_User" */
  Group_Calendar_User: Array<GroupCalendarUser>;
  /** fetch aggregated fields from the table: "Group_Calendar_User" */
  Group_Calendar_User_aggregate: GroupCalendarUserAggregate;
  /** fetch data from the table: "Group_Calendar_User" using primary key columns */
  Group_Calendar_User_by_pk?: Maybe<GroupCalendarUser>;
  /** fetch aggregated fields from the table: "Group_Calendar" */
  Group_Calendar_aggregate: GroupCalendarAggregate;
  /** fetch data from the table: "Group_Calendar" using primary key columns */
  Group_Calendar_by_pk?: Maybe<GroupCalendar>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: UserAggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
}


/** subscription root */
export interface SubscriptionRootAsuviRolesArgs {
  distinct_on?: Maybe<Array<AsuviRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AsuviRolesOrderBy>>;
  where?: Maybe<AsuviRolesBoolExp>;
}


/** subscription root */
export interface SubscriptionRootAsuviRolesAggregateArgs {
  distinct_on?: Maybe<Array<AsuviRolesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AsuviRolesOrderBy>>;
  where?: Maybe<AsuviRolesBoolExp>;
}


/** subscription root */
export interface SubscriptionRootAsuviRolesByPkArgs {
  value: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootCalendarArgs {
  distinct_on?: Maybe<Array<CalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarOrderBy>>;
  where?: Maybe<CalendarBoolExp>;
}


/** subscription root */
export interface SubscriptionRootCalendarProvidersArgs {
  distinct_on?: Maybe<Array<CalendarProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarProvidersOrderBy>>;
  where?: Maybe<CalendarProvidersBoolExp>;
}


/** subscription root */
export interface SubscriptionRootCalendarProvidersAggregateArgs {
  distinct_on?: Maybe<Array<CalendarProvidersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarProvidersOrderBy>>;
  where?: Maybe<CalendarProvidersBoolExp>;
}


/** subscription root */
export interface SubscriptionRootCalendarProvidersByPkArgs {
  value: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootCalendarAggregateArgs {
  distinct_on?: Maybe<Array<CalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CalendarOrderBy>>;
  where?: Maybe<CalendarBoolExp>;
}


/** subscription root */
export interface SubscriptionRootCalendarByPkArgs {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootEventStatusArgs {
  distinct_on?: Maybe<Array<EventStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventStatusOrderBy>>;
  where?: Maybe<EventStatusBoolExp>;
}


/** subscription root */
export interface SubscriptionRootEventStatusAggregateArgs {
  distinct_on?: Maybe<Array<EventStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventStatusOrderBy>>;
  where?: Maybe<EventStatusBoolExp>;
}


/** subscription root */
export interface SubscriptionRootEventStatusByPkArgs {
  value: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootEventsArgs {
  distinct_on?: Maybe<Array<EventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventsOrderBy>>;
  where?: Maybe<EventsBoolExp>;
}


/** subscription root */
export interface SubscriptionRootEventsAggregateArgs {
  distinct_on?: Maybe<Array<EventsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EventsOrderBy>>;
  where?: Maybe<EventsBoolExp>;
}


/** subscription root */
export interface SubscriptionRootEventsByPkArgs {
  id: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootGroupCalendarArgs {
  distinct_on?: Maybe<Array<GroupCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarOrderBy>>;
  where?: Maybe<GroupCalendarBoolExp>;
}


/** subscription root */
export interface SubscriptionRootGroupCalendarCalendarArgs {
  distinct_on?: Maybe<Array<GroupCalendarCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarCalendarOrderBy>>;
  where?: Maybe<GroupCalendarCalendarBoolExp>;
}


/** subscription root */
export interface SubscriptionRootGroupCalendarCalendarAggregateArgs {
  distinct_on?: Maybe<Array<GroupCalendarCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarCalendarOrderBy>>;
  where?: Maybe<GroupCalendarCalendarBoolExp>;
}


/** subscription root */
export interface SubscriptionRootGroupCalendarCalendarByPkArgs {
  calId: Scalars['String'];
  groupCalId: Scalars['String'];
  provider: CalendarProvidersEnum;
  userId: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootGroupCalendarUserArgs {
  distinct_on?: Maybe<Array<GroupCalendarUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarUserOrderBy>>;
  where?: Maybe<GroupCalendarUserBoolExp>;
}


/** subscription root */
export interface SubscriptionRootGroupCalendarUserAggregateArgs {
  distinct_on?: Maybe<Array<GroupCalendarUserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarUserOrderBy>>;
  where?: Maybe<GroupCalendarUserBoolExp>;
}


/** subscription root */
export interface SubscriptionRootGroupCalendarUserByPkArgs {
  groupCalId: Scalars['String'];
  userId: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootGroupCalendarAggregateArgs {
  distinct_on?: Maybe<Array<GroupCalendarSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GroupCalendarOrderBy>>;
  where?: Maybe<GroupCalendarBoolExp>;
}


/** subscription root */
export interface SubscriptionRootGroupCalendarByPkArgs {
  id: Scalars['String'];
}


/** subscription root */
export interface SubscriptionRootUserArgs {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
}


/** subscription root */
export interface SubscriptionRootUserAggregateArgs {
  distinct_on?: Maybe<Array<UserSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserOrderBy>>;
  where?: Maybe<UserBoolExp>;
}


/** subscription root */
export interface SubscriptionRootUserByPkArgs {
  uid: Scalars['String'];
}


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export interface TimestamptzComparisonExp {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export interface UuidComparisonExp {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
}

export type UpsertCalendarsMutationVariables = Exact<{
  objects: Array<CalendarInsertInput> | CalendarInsertInput;
  provider: CalendarProvidersEnum;
}>;


export type UpsertCalendarsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_Calendar?: Maybe<(
    { __typename?: 'Calendar_mutation_response' }
    & Pick<CalendarMutationResponse, 'affected_rows'>
  )>, insert_Calendar?: Maybe<(
    { __typename?: 'Calendar_mutation_response' }
    & Pick<CalendarMutationResponse, 'affected_rows'>
  )> }
);

export type UpsertEventsMutationVariables = Exact<{
  objects: Array<EventsInsertInput> | EventsInsertInput;
  calId: Scalars['String'];
  provider: CalendarProvidersEnum;
  syncToken: Scalars['String'];
  updateColumns: Array<EventsUpdateColumn> | EventsUpdateColumn;
}>;


export type UpsertEventsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Events?: Maybe<(
    { __typename?: 'Events_mutation_response' }
    & Pick<EventsMutationResponse, 'affected_rows'>
  )>, update_Calendar?: Maybe<(
    { __typename?: 'Calendar_mutation_response' }
    & Pick<CalendarMutationResponse, 'affected_rows'>
  )> }
);

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['String'];
  provider: CalendarProvidersEnum;
  deleted: Scalars['Boolean'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'mutation_root' }
  & { update_Events?: Maybe<(
    { __typename?: 'Events_mutation_response' }
    & Pick<EventsMutationResponse, 'affected_rows'>
  )> }
);

export type DeleteRecurringEventsMutationVariables = Exact<{
  recurringEventId: Scalars['String'];
  provider: CalendarProvidersEnum;
}>;


export type DeleteRecurringEventsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_Events?: Maybe<(
    { __typename?: 'Events_mutation_response' }
    & Pick<EventsMutationResponse, 'affected_rows'>
  )> }
);

export type MyCalendarsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCalendarsQuery = (
  { __typename?: 'query_root' }
  & { Calendar: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'sync' | 'title' | 'provider' | 'id' | 'description' | 'uuid' | 'color' | 'primary'>
  )> }
);

export type MySyncedCalendarsQueryVariables = Exact<{ [key: string]: never; }>;


export type MySyncedCalendarsQuery = (
  { __typename?: 'query_root' }
  & { Calendar: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'sync' | 'title' | 'provider' | 'id' | 'description' | 'uuid' | 'color' | 'primary'>
  )> }
);

export type SyncExternalCalendarMutationVariables = Exact<{
  calId: Scalars['String'];
  provider: Provider;
  sync: Scalars['Boolean'];
}>;


export type SyncExternalCalendarMutation = (
  { __typename?: 'mutation_root' }
  & { syncExternalCalendar?: Maybe<(
    { __typename?: 'Output' }
    & Pick<Output, 'calendarId' | 'error' | 'sync'>
  )> }
);

export type MyEventsQueryVariables = Exact<{
  endTime: Scalars['timestamptz'];
  startTime: Scalars['timestamptz'];
}>;


export type MyEventsQuery = (
  { __typename?: 'query_root' }
  & { Calendar: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'color' | 'provider' | 'sync' | 'lastSynced' | 'title' | 'uuid'>
    & { Events: Array<(
      { __typename?: 'Events' }
      & Pick<Events, 'endTime' | 'startTime' | 'title' | 'description' | 'startDate' | 'endDate' | 'id' | 'allDay'>
    )> }
  )> }
);

export const UpsertCalendarsDocument = gql`
    mutation UpsertCalendars($objects: [Calendar_insert_input!]!, $provider: Calendar_Providers_enum!) {
  delete_Calendar(where: {_and: {provider: {_eq: $provider}, sync: {_eq: false}}}) {
    affected_rows
  }
  insert_Calendar(
    objects: $objects
    on_conflict: {constraint: Calendar_pkey, update_columns: [title, description, canEdit, color, primary]}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertCalendarsGQL extends Apollo.Mutation<UpsertCalendarsMutation, UpsertCalendarsMutationVariables> {
    document = UpsertCalendarsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpsertEventsDocument = gql`
    mutation UpsertEvents($objects: [Events_insert_input!]!, $calId: String!, $provider: Calendar_Providers_enum!, $syncToken: String!, $updateColumns: [Events_update_column!]!) {
  insert_Events(
    objects: $objects
    on_conflict: {constraint: Events_pkey, update_columns: $updateColumns}
  ) {
    affected_rows
  }
  update_Calendar(
    where: {_and: [{id: {_eq: $calId}}, {provider: {_eq: $provider}}]}
    _set: {syncToken: $syncToken, lastSynced: "now()"}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertEventsGQL extends Apollo.Mutation<UpsertEventsMutation, UpsertEventsMutationVariables> {
    document = UpsertEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEventDocument = gql`
    mutation DeleteEvent($eventId: String!, $provider: Calendar_Providers_enum!, $deleted: Boolean!) {
  update_Events(
    where: {_and: [{id: {_eq: $eventId}}, {provider: {_eq: $provider}}]}
    _set: {deleted: $deleted}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEventGQL extends Apollo.Mutation<DeleteEventMutation, DeleteEventMutationVariables> {
    document = DeleteEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRecurringEventsDocument = gql`
    mutation DeleteRecurringEvents($recurringEventId: String!, $provider: Calendar_Providers_enum!) {
  delete_Events(
    where: {recurringEventId: {_eq: $recurringEventId}, provider: {_eq: $provider}}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRecurringEventsGQL extends Apollo.Mutation<DeleteRecurringEventsMutation, DeleteRecurringEventsMutationVariables> {
    document = DeleteRecurringEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyCalendarsDocument = gql`
    query MyCalendars {
  Calendar(order_by: {sync: desc, primary: desc}) {
    sync
    title
    provider
    id
    description
    uuid
    color
    primary
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCalendarsGQL extends Apollo.Query<MyCalendarsQuery, MyCalendarsQueryVariables> {
    document = MyCalendarsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MySyncedCalendarsDocument = gql`
    query MySyncedCalendars {
  Calendar(where: {sync: {_eq: true}}) {
    sync
    title
    provider
    id
    description
    uuid
    color
    primary
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MySyncedCalendarsGQL extends Apollo.Query<MySyncedCalendarsQuery, MySyncedCalendarsQueryVariables> {
    document = MySyncedCalendarsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SyncExternalCalendarDocument = gql`
    mutation SyncExternalCalendar($calId: String!, $provider: Provider!, $sync: Boolean!) {
  syncExternalCalendar(calId: $calId, provider: $provider, sync: $sync) {
    calendarId
    error
    sync
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SyncExternalCalendarGQL extends Apollo.Mutation<SyncExternalCalendarMutation, SyncExternalCalendarMutationVariables> {
    document = SyncExternalCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyEventsDocument = gql`
    query MyEvents($endTime: timestamptz!, $startTime: timestamptz!) {
  Calendar(where: {sync: {_eq: true}}) {
    Events(
      where: {endTime: {_lte: $endTime}, startTime: {_gte: $startTime}, deleted: {_eq: false}}
    ) {
      endTime
      startTime
      title
      description
      startDate
      endDate
      id
      allDay
    }
    color
    provider
    sync
    lastSynced
    title
    uuid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyEventsGQL extends Apollo.Query<MyEventsQuery, MyEventsQueryVariables> {
    document = MyEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }