import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthService } from '../auth.service';
import {
  IGetOCalEventsResponse,
  IGetOCalReponse,
} from './models/outlook-calendar';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftCalendarService {
  private graphClient: Client;

  constructor(private auth: AuthService) {}

  private initGraphClient(): void {
    console.log('init graph client was called');
    this.graphClient = Client.init({
      authProvider: async (done) => {
        const token = await this.auth.getMsalAccessToken().catch((reason) => {
          console.error('Graph Client Could not get Access Token', reason);
          done(reason, null);
        });
        if (token) {
          done(null, token);
        } else {
          console.error('getMsalAccessToken returns with invalid token');
          done('Could not get an access token', null);
        }
      },
    });
  }

  public async getProfile() {
    this.initGraphClient();
    console.log('get profile called');
    try {
      const result = await this.graphClient.api('/me').get();
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Get all the calendars that belong to the user
   */
  async getCalendars(): Promise<IGetOCalReponse> {
    this.initGraphClient();
    try {
      const { value } = await this.graphClient.api('/me/calendars').get();
      return { calendars: value };
    } catch (error) {
      console.log(error);
      return { calendars: [], error: 'Error when retrieving calendars' };
    }
  }

  /**
   * Gets all the events that the has for that calendar (no-sync)
   * @param calendarId Calendar identifier
   */
  async getEvents(calendarId: string) {
    this.initGraphClient();
    try {
      const { value } = await this.graphClient
        .api(`/me/calendars/${calendarId}/events`)
        .get();
      return { events: value };
    } catch (error) {
      console.log(error);
      return { events: [], error: 'Error when retrieving calendar events' };
    }
  }

  /**
   * Returns the Calendar View for the time frame
   * @param calendarId Calendar identifier
   * @param startTime Look up start time
   * @param endTime Look up end time
   * See More: https://docs.microsoft.com/en-us/graph/api/calendar-list-calendarview?view=graph-rest-1.0&tabs=http
   */
  async getCalendarView(calendarId: string, startTime: Date, endTime: Date) {
    this.initGraphClient();
    try {
      const { value } = await this.graphClient
        .api(
          `/me/calendars/${calendarId}/calendarView?startDateTime=${startTime.toISOString()}&endDateTime=${endTime.toISOString()}`
        )
        .get();
      return { events: value };
    } catch (error) {
      console.log(error);
      return { events: [], error: 'Error when retrieving calendar events' };
    }
  }

  /**
   * Returns the incremental changes in the Calendar
   * @param calendarId Calendar identifier
   * @param skipToken Skip token
   * @param startTime Look up start time
   * @param endTime Look up end time
   * See more: https://docs.microsoft.com/en-us/graph/delta-query-events?tabs=javascript
   */
  public async getCalendarDelta(
    calendarId: string,
    syncToken: string,
    pageToken?: string,
    startTime?: Date,
    endTime?: Date
  ): Promise<IGetOCalEventsResponse> {
    this.initGraphClient();
    try {
      if (syncToken) {
        const result = await this.graphClient
          .api(
            `/me/calendars/${calendarId}/calendarView/delta?$deltatoken=${syncToken}`
          )
          .get();
        return this.getCalendarDeltaHelper(result, calendarId);
      } else if (pageToken) {
        const result = await this.graphClient
          .api(`/me/calendars/${calendarId}/calendarView/delta`)
          .skipToken(pageToken)
          .get();
        return this.getCalendarDeltaHelper(result, calendarId);
      } else if (startTime && endTime) {
        const result = await this.graphClient
          .api(
            `/me/calendars/${calendarId}/calendarView/delta?startDateTime=${startTime.toISOString()}&endDateTime=${endTime.toISOString()}`
          )
          .get();
        return this.getCalendarDeltaHelper(result, calendarId);
      } else {
        return { events: [], error: 'No start and end time specified' };
      }
    } catch (error) {
      console.log(error);
      return { events: [], error: 'Error when retrieving calendar events' };
    }
  }

  private async getCalendarDeltaHelper(result: any, calendarId: string) {
    if (result['@odata.nextLink']) {
      // There is another page to fetch
      const pageToken = result['@odata.nextLink'].split('skiptoken=').pop();
      const { events, nextSyncToken } = await this.getCalendarDelta(
        calendarId,
        null,
        pageToken
      );
      return { events: result.value.concat(events), nextSyncToken };
    } else if (result['@odata.deltaLink']) {
      // All pages fetched, return syncToken
      const syncToken = result['@odata.deltaLink'].split('deltatoken=').pop();
      return { events: result.value, nextSyncToken: syncToken };
    } else {
      return {
        events: [],
        error: 'Graph API returned with invalid response',
      };
    }
  }

  //TODO: List instances?
  // See more: https://docs.microsoft.com/en-us/graph/api/event-list-instances?view=graph-rest-1.0&tabs=http
  // Explorer: https://developer.microsoft.com/en-us/graph/graph-explorer
}
