import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss'],
})
export class DialogBoxComponent implements OnInit {
  NewCalendar: FormGroup;
  constructor(private router: Router, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.NewCalendar = this.fb.group({
      roomtName: new FormControl('', [Validators.required]),
      Description: new FormControl('', [Validators.required]),
    });
  }

  saveButton(event: Event) {
    console.log('save button works !');
    event.stopPropagation();
  }
  CancelButton(event: Event) {
    console.log('Cancel button works !');
    event.stopPropagation();
  }
}
