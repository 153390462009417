<div class="layout-header-container">
  <div>
    <div class="layout-header-logo" *ngIf="!title">
      <img src="/assets/asuvi_logo.svg" />
    </div>
    <div class="layout-header-logo" *ngIf="title">
      <img src="/assets/asuvi_logo_monogram.svg" />
      <span>{{ title }}</span>
    </div>
  </div>
  <div>
    <span>John Doe</span>
  </div>
</div>

<main class="layout-main-content">
  <ng-content></ng-content>
</main>
